/* global google */
import React from 'react';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import { Currencies } from '../constants/Common-Currency';
import inside from 'point-inside-polygon';
import { I18n } from 'react-redux-i18n'
import { browserHistory } from 'react-router'
import packageJson from '../../package.json'
import Bowser from "bowser";
import {
  activeStatusAll,
  activeStatus,
  activeStatusDelivery,
  finishedStatus,
  mapIcons,
  tripStatus,
  capacityStatus,
  tripTypes,
  ModalEditFareField,
  gateway_port_21,
  STATUS_COMPLETED_DELIVERY,
  SUPPLIER_TYPE,
  userType,
  APPLY_SERVICE_TYPE,
  PAYOUT_CUSTOM_TYPE,
  PAID_STATUS,
  STATUS_GROUP_BOOKING,
  TYPE_BOOKING_GROUP,
  STATUS_ASSIGN_SUPLIERS,
  thirdPartyIntegration,
  AFFILIATE_BOOKING_CAR_TYPE,
  FEE_TYPE,
  STATUS_FLEET_SERVICE,
  STATUS_ASSIGN_ONE_DRIVER,
  BOOK_TYPE,
  STATUS_ASSIGN_MULTI_DRIVER,
  STATUS_ASSIGN_DRIVER,
  UNIT_TIME,
  STATUS_BOOKING,
  NAME_GATEWAY,
  REGULAR_MODE,
  TIME_OR_MILEAGE_BASIC_FARE_FIELD,
  offerStatus
} from '../constants/commondata';
import { outerTableChangeEvent } from '../constants/customEvent';
import TimeZones from '../components/timeZonePicker/timezones';
import { getTimezone } from '../actions/mapProviderAction'
import parse from 'html-react-parser'
const momenttz = require('moment-timezone');
import {
  socketDispatchApi
} from '../utils/socketUtils';
import { socketConfigs } from '../constants/socketConfigs';
import auth from '../reducers/auth';
import { loadUserProfile } from './apiUtils';
import axios from 'axios';
import { notifyCrashClientAPI } from '../constants/ApiConfigs';
const _ = require('lodash');

const moment = extendMoment(Moment);
const defaultSetting = {
  symbol: '$',
  name: 'US Dollar',
  symbol_native: '$',
  decimal_digits: 2,
  rounding: 0,
  code: 'USD',
  name_plural: 'US dollars',
};
export const CCLiteCommonFunc = {
  cardNumberFormat (value) {
    const cardNumber = value.replace(/\s+/g, '');
    const result = [];
    if (cardNumber.length <= 16) {
      do {
        let endSub = result.length * 4 + 4;
        if (endSub > cardNumber.length) {
          endSub = cardNumber.length;
        }
        result.push(cardNumber.substring(result.length * 4, endSub));
      } while (result.length * 4 < cardNumber.length);
    }
    return result.join(' ');
  },
  currencyNumberFormat (value, money) {
    const moneySetting =
      Currencies[money] || defaultSetting;
    return parseFloat(parseFloat(value).toFixed(moneySetting.decimal_digits));
  },
  currencySymbol (money) {
    if (Currencies[money]) {
      return Currencies[money];
    }
    Currencies.map((mn) => {
      if (mn.symbol == money) {
        return mn;
      }
    });
  },
  convertFilterToQuery (filter, selectTab = 0) {
    const query = Object.assign({}, filter);
    query.carType = filter.carType.map((item) => {
      if (item != null && (item.vehicleType != '' || item.vehicleType != null))
        return item.vehicleType;
    });
    if (filter.requestCompanyIds) {
      query.requestCompanyIds = filter.requestCompanyIds.map((item) => {
        if (item._id) return item._id;
      });
    }

    if (
      filter.intercityRoutes &&
      filter.intercityRoutes[0] &&
      filter.intercityRoutes[0]._id
    ) {
      query.routeId = filter.intercityRoutes[0]._id;
      query.routeNumber = filter.intercityRoutes[0].routeNumber;
    } else {
      delete query.routeId;
      delete query.routeNumber;
    }

    if (query.intercityRoutes) {
      delete query.intercityRoutes;
    }

    if (selectTab == 0) {
      // custome query for active cue
      query.status = Object.keys(filter.status).map((key) => {
        if (filter.status[key]) return key;
      });
      if (filter.bookingType === 'all') {
        query.status = activeStatusAll.map((status) => {
          if (query.status.includes(status.group)) return status.code;
        });
      } else if (filter.bookingType === 'delivery') {
        query.status = activeStatusDelivery.map((status) => {
          if (query.status.includes(status.group)) return status.code;
        });
      } else {
        query.status = activeStatus.map((status) => {
          if (query.status.includes(status.group)) return status.code;
        });
      }
      delete query.paymentMethod;
      delete query.driverCorporate;
      delete query.rideSharing;
      // delete query.rideService;
      delete query.corporate;
    } else if (selectTab == 3) {
      query.status = Object.keys(filter.status).map((key) => {
        if (filter.status[key]) return key;
      });
      query.status = offerStatus.map((status) => {
        if (query.status.includes(status.group)) return status.code;
      });
      query.isOffer = true;
      delete query.paymentMethod;
      delete query.driverCorporate;
      delete query.rideSharing;
      // delete query.rideService;
      delete query.corporate;
    } else {
      // custome query for finished cue
      query.status = Object.keys(filter.finishedStatus).map((key) => {
        if (filter.finishedStatus[key]) return key;
      });
      query.status = finishedStatus.map((status) => {
        if (query.status.includes(status.group)) return status.code;
      });

      query.paymentMethod = filter.paymentMethod
        ? filter.paymentMethod.map((item) => {
          if (item != null && (item.value != '' || item.value != null))
            return item.value;
        })
        : [];
    }
    query.status = query.status.clean(null);
    query.status = query.status.clean('all');
    query.bookFrom = filter.bookFrom.map((item) => {
      if (item != null && (item.value != '' || item.value != null))
        return item.value;
    });
    query.dateFrom = query.dateFrom
      ? moment(query.dateFrom)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toISOString()
      : null;
    query.dateTo = query.dateTo
      ? moment(query.dateTo)
        .hour(23)
        .minute(59)
        .second(0)
        .millisecond(59)
        .toISOString() // reset Date to hour 00:00:000
      : null;
    let fleetObject;

    try {
      fleetObject = JSON.parse(localStorage.getItem('fleet')) || {};
    } catch (err) {
      fleetObject = {};
    }
    query.fleetId = fleetObject.fleetId;
    switch (filter.vip) {
      case -1: {
        query.vip = null;
        break;
      }
      case 0: {
        query.vip = false;
        break;
      }
      case 1: {
        query.vip = true;
        break;
      }
    }
    delete query.finishedStatus;
    delete query.sort;
    return query;
  },
  convertFilterToQueryTrip (filter, isActive = true) {
    const query = Object.assign({}, filter);

    query.carType = filter.carType.map((item) => {
      if (item != null && (item.vehicleType != '' || item.vehicleType != null))
        return item.vehicleType;
    });

    if (
      filter.intercityRoutes &&
      filter.intercityRoutes[0] &&
      filter.intercityRoutes[0]._id
    ) {
      query.routeId = filter.intercityRoutes[0]._id;
      query.routeNumber = filter.intercityRoutes[0].routeNumber;
    } else {
      delete query.routeId;
      delete query.routeNumber;
    }

    if (query.intercityRoutes) {
      delete query.intercityRoutes;
    }
    query.dateFrom = query.dateFrom
      ? moment(query.dateFrom)
        .hour(0)
        .minute(0)
        .second(0)
        .millisecond(0)
        .toISOString()
      : null;
    query.dateTo = query.dateTo
      ? moment(query.dateTo)
        .hour(23)
        .minute(59)
        .second(0)
        .millisecond(59)
        .toISOString() // reset Date to hour 00:00:000
      : null;

    query.status = Object.keys(filter.status).map((key) => {
      if (filter.status[key]) return key;
    });
    query.status = tripStatus.map((status) => {
      if (query.status.includes(status.group)) return status.code;
    });
    query.status = query.status.clean(null);
    query.status = query.status.clean('all');

    query.capacityStatus = Object.keys(filter.capacityStatus).map((key) => {
      if (filter.capacityStatus[key]) return key;
    });
    query.capacityStatus = capacityStatus.map((status) => {
      if (query.capacityStatus.includes(status.group)) return status.code;
    });
    query.capacityStatus = query.capacityStatus.clean(null);
    query.capacityStatus = query.capacityStatus.clean('all');

    // Trip types convert
    query.tripTypes = Object.keys(filter.tripTypes).map((key) => {
      if (filter.tripTypes[key]) return key;
    });
    query.tripTypes = tripTypes.map((status) => {
      if (query.tripTypes.includes(status.group)) return status.code;
    });
    query.tripTypes = query.tripTypes.clean(null);
    query.tripTypes = query.tripTypes.clean('all');

    let fleetObject;

    try {
      fleetObject = JSON.parse(localStorage.getItem('fleet')) || {};
    } catch (err) {
      fleetObject = {};
    }

    query.fleetId = fleetObject.fleetId;

    return query;
  },

  createDriversMapItem (driver, isChina = false) {
    if (!driver) return null;
    let voipToken = localStorage.getItem('voipToken');
    let qd = localStorage.getItem('qd');
    let webvoipdomain = localStorage.getItem('webvoipdomain');
    let hasJobDelivery = false;
    let numberJobDelivery = 1;

    let marker = {
      position:
        driver.loc && driver.loc.coordinates
          ? isChina
            ? new window.qq.maps.LatLng(
              driver.loc.coordinates[1],
              driver.loc.coordinates[0]
            )
            : new google.maps.LatLng(
              driver.loc.coordinates[1],
              driver.loc.coordinates[0]
            )
          : isChina
            ? new window.qq.maps.LatLng(0, 0)
            : new google.maps.LatLng(0, 0),
      defaultAnimation: 0,
      key: driver._id,
      showInfo: false,
      driver: driver,
      // infoContent: '',
      infoMaxWidth: 300,
    };
    let bookId = '';
    let address = '';
    if (driver.status == 'inProgress') {
      let statuses = driver.currentJob.map((job) => {
        if (job) {
          if (job.bookId) {
            bookId += ` <a class="notHref" style="cursor: pointer" onclick="openBookingDetail('${job.bookId}')">#${job.bookId}</a>`;
          }
          if (job.delivery) hasJobDelivery = true;
          return job.status;
        }
        return '';
      });
      if (
        hasJobDelivery &&
        driver.currentJob &&
        driver.currentJob.length >= 2
      ) {
        numberJobDelivery = driver.currentJob.length;
        marker.icon = mapIcons['multiDeliveryOrder'];
        // return marker
      } else {
        statuses = _.uniq(statuses);
        if (statuses.length === 1 && driver.currentJob.length >= 2) {
          statuses = statuses.concat(statuses);
        }
        statuses = statuses.sort().join('_');
        if (
          driver.currentJob.length >= 2 &&
          !driver.currentJob[0].drs &&
          driver.vehicle.vehicleType !== 'Intercity'
        ) {
          statuses += '_driver';
        }
        if (!mapIcons[statuses]) {
        }
        marker.icon = mapIcons[statuses] || mapIcons.defaultMapIcon;
        let listShowPU = ['booked', 'offered', 'arrived', 'action'];
        let listShowDO = ['engaged', 'droppedOff'];
        if (
          [...listShowDO, ...listShowPU].indexOf(statuses) > -1 &&
          driver.currentJob.length === 1
        ) {
          let addressText = '';
          if (listShowPU.indexOf(statuses) > -1) {
            addressText = _.get(
              driver.currentJob[0],
              'bookingDetail.request.pickup.address',
              ''
            );
          } else {
            addressText = _.get(
              driver.currentJob[0],
              'bookingDetail.request.destination.address',
              ''
            );
          }
          if (addressText) {
            address = `<p class='contentMarker'> 
              <span><img src='${mapIcons['address']}' /></span>
              <span> ${addressText} </span>
            </p>`;
          }
        }
      }
    } else {
      marker.icon = mapIcons[driver.status] || mapIcons.defaultMapIcon;
    }

    let infoContent = "<div class='marker-info'>";
    // <p>${bookId.substring(1)}</p>
    infoContent += `
      <p>${bookId}</p>
      <p class='contentMarker'>
        <span><img src='${mapIcons['steeringWheel']}' /></span>
        <span>${driver.firstName} ${driver.lastName}/`;
    if (voipToken.length > 0) {
      var lengthDriverName =
        10 * (driver.firstName.length + driver.lastName.length) + 350;
      infoContent += `<span style="color:#4994b9;cursor: pointer"  onclick='window.open("${webvoipdomain}?token=${voipToken}&fleetId=${driver.fleetId}&driverName=${driver.firstName} ${driver.lastName}&phone=${driver.phone}&plateNumber=${driver.vehicle.plateNumber}&vehicleType=${driver.vehicle.vehicleType}&avatar=${driver.s3Host}${driver.avatar}&qd=${qd}", "myWindow", "width=${lengthDriverName},height=223");'>	
        <u dir="ltr" >${driver.phone}</u>	
      </span>	
      </span></p>`;
    } else {
      infoContent += `<span dir="ltr" >	
        ${driver.phone}	
      </span>	
      </span></p>`;
    }
    if (driver.vehicle) {
      infoContent += `<p class='contentMarker'> <span><img src='${
        mapIcons['car']
      }' /></span> <span>${driver.vehicle.vehicleType || ''}</span><span>/ ${
        driver.vehicle.plateNumber || ''
      }</span></p>`;
    }
    if (address) {
      infoContent += address;
    }

    if (hasJobDelivery && numberJobDelivery >= 2) {
      infoContent += `<p><i class='fa fa-car' hidden='true' style='color:white'></i><span>${numberJobDelivery} Orders</span></p></div>`;
    } else {
      infoContent += `</div>`;
    }

    marker.infoContent = infoContent;
    return marker;
  },
  createMapItemFromBooking (booking, isChina = false) {
    if (!booking || !booking.request) {
      return null;
    }
    let infoContent = "<div class='marker-info'>";
    if (booking.psgInfo) {
      infoContent += `<p><a class="notHref" style="cursor: pointer" onclick="openBookingDetail('${booking.bookId
        }')">#${booking.bookId}</a></p>
        <p><i class='fa fa-user' aria-hidden='true'></i>:<span>${booking.psgInfo.firstName !== 'Car-hailing'
          ? `${booking.psgInfo.firstName} ${booking.psgInfo.lastName}`
          : 'Car-hailing'
        }</span></p>`;
    }
    infoContent += `<p><i class='fa fa-map-marker' aria-hidden='true'></i>:<span>${booking.request && booking.request.pickup
      ? booking.request.pickup.address
      : ''
      }</span></p></div>`;
    const marker = {
      position: isChina
        ? new window.qq.maps.LatLng(
          booking.request.pickup.geo[1],
          booking.request.pickup.geo[0]
        )
        : new google.maps.LatLng(
          booking.request.pickup.geo[1],
          booking.request.pickup.geo[0]
        ),
      defaultAnimation: 0,
      icon: mapIcons[booking.status] || mapIcons.defaultMapIcon,
      key: booking._id,
      showInfo: false,
      isItemBooking: true,
      infoContent,
      infoMaxWidth: 300,
    };
    return marker;
  },
  getSelectedOptons (e) {
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    return value;
  },
  isFormValid (data) {
    let isValid = true;
    Object.keys(data).every((key) => {
      if (!data[key]) {
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  },
  isFormValidDriver (data, list) {
    let isValid = true;
    Object.keys(data).every((key) => {
      if (!data.zone) {
        isValid = false;
        return false;
      }
      if (!data.type) {
        isValid = false;
        return false;
      }
      if (data.licensePlate === false) {
        isValid = false;
        return false;
      }
      if (!data.company) {
        isValid = false;
        return false;
      }
      if (!data[key] && _.filter(list, { key: key }).length != 0) {
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  },
  isFormValidDocument (data, list) {
    let isValid = true;
    Object.keys(data).every((key) => {
      if (
        (!data[key] && _.filter(list, { documentKey: key }).length != 0) ||
        !data[key]
      ) {
        isValid = false;
        return false;
      }
      return true;
    });
    return isValid;
  },
  getFailValidKey (data) {
    let isValid = null;
    Object.keys(data).every((key) => {
      if (!data[key]) {
        isValid = key;
        return false;
      }
      return true;
    });
    return isValid;
  },

  getValueOfMultiCurrencies (valueArray, iso, valueKey) {
    let value = 0;
    valueArray.map((c) => {
      if (iso == c.currencyISO) {
        value = c[valueKey];
      }
    });
    return value;
  },
  stringToLanguageKey (key, root) {
    key = key.replace(/[.*+?^${}()|[\]\\]/g, '');
    key = key.replace("'s", '');
    key = key.replace(/\s/g, '_');
    return `${root || ''}.${key}`;
  },
  getActiveMenuMainLink (selectedMenu) {
    let { link } = selectedMenu;
    if (selectedMenu.sub && selectedMenu.sub.length > 0) {
      selectedMenu.sub.every((mn) => {
        if (mn.active) {
          link = this.getActiveMenuMainLink(mn);
          return false;
        }
        return true;
      });
    }
    return link;
  },
  checkShowedMenuModule (key, menuList) {
    if (!menuList) return false;
    let menu = null;
    menuList.every((mn) => {
      if (mn.enabled && mn.show) {
        if (key == mn.key) {
          menu = mn;
        } else if (mn.sub && mn.sub.length > 0) {
          menu = this.checkShowedMenuModule(key, mn.sub);
        }
      }
      if (menu) {
        return false;
      }
      return true;
    });

    return menu;
  },
  removeAccentsDiacritics (input) {
    const defaultDiacriticsRemovalMap = [
      {
        base: 'A',
        letters:
          '\u0041\u24B6\uFF21\u00C0\u00C1\u00C2\u1EA6\u1EA4\u1EAA\u1EA8\u00C3\u0100\u0102\u1EB0\u1EAE\u1EB4\u1EB2\u0226\u01E0\u00C4\u01DE\u1EA2\u00C5\u01FA\u01CD\u0200\u0202\u1EA0\u1EAC\u1EB6\u1E00\u0104\u023A\u2C6F',
      },
      { base: 'AA', letters: '\uA732' },
      { base: 'AE', letters: '\u00C6\u01FC\u01E2' },
      { base: 'AO', letters: '\uA734' },
      { base: 'AU', letters: '\uA736' },
      { base: 'AV', letters: '\uA738\uA73A' },
      { base: 'AY', letters: '\uA73C' },
      {
        base: 'B',
        letters: '\u0042\u24B7\uFF22\u1E02\u1E04\u1E06\u0243\u0182\u0181',
      },
      {
        base: 'C',
        letters:
          '\u0043\u24B8\uFF23\u0106\u0108\u010A\u010C\u00C7\u1E08\u0187\u023B\uA73E',
      },
      {
        base: 'D',
        letters:
          '\u0044\u24B9\uFF24\u1E0A\u010E\u1E0C\u1E10\u1E12\u1E0E\u0110\u018B\u018A\u0189\uA779\u00D0',
      },
      { base: 'DZ', letters: '\u01F1\u01C4' },
      { base: 'Dz', letters: '\u01F2\u01C5' },
      {
        base: 'E',
        letters:
          '\u0045\u24BA\uFF25\u00C8\u00C9\u00CA\u1EC0\u1EBE\u1EC4\u1EC2\u1EBC\u0112\u1E14\u1E16\u0114\u0116\u00CB\u1EBA\u011A\u0204\u0206\u1EB8\u1EC6\u0228\u1E1C\u0118\u1E18\u1E1A\u0190\u018E',
      },
      { base: 'F', letters: '\u0046\u24BB\uFF26\u1E1E\u0191\uA77B' },
      {
        base: 'G',
        letters:
          '\u0047\u24BC\uFF27\u01F4\u011C\u1E20\u011E\u0120\u01E6\u0122\u01E4\u0193\uA7A0\uA77D\uA77E',
      },
      {
        base: 'H',
        letters:
          '\u0048\u24BD\uFF28\u0124\u1E22\u1E26\u021E\u1E24\u1E28\u1E2A\u0126\u2C67\u2C75\uA78D',
      },
      {
        base: 'I',
        letters:
          '\u0049\u24BE\uFF29\u00CC\u00CD\u00CE\u0128\u012A\u012C\u0130\u00CF\u1E2E\u1EC8\u01CF\u0208\u020A\u1ECA\u012E\u1E2C\u0197',
      },
      { base: 'J', letters: '\u004A\u24BF\uFF2A\u0134\u0248' },
      {
        base: 'K',
        letters:
          '\u004B\u24C0\uFF2B\u1E30\u01E8\u1E32\u0136\u1E34\u0198\u2C69\uA740\uA742\uA744\uA7A2',
      },
      {
        base: 'L',
        letters:
          '\u004C\u24C1\uFF2C\u013F\u0139\u013D\u1E36\u1E38\u013B\u1E3C\u1E3A\u0141\u023D\u2C62\u2C60\uA748\uA746\uA780',
      },
      { base: 'LJ', letters: '\u01C7' },
      { base: 'Lj', letters: '\u01C8' },
      {
        base: 'M',
        letters: '\u004D\u24C2\uFF2D\u1E3E\u1E40\u1E42\u2C6E\u019C',
      },
      {
        base: 'N',
        letters:
          '\u004E\u24C3\uFF2E\u01F8\u0143\u00D1\u1E44\u0147\u1E46\u0145\u1E4A\u1E48\u0220\u019D\uA790\uA7A4',
      },
      { base: 'NJ', letters: '\u01CA' },
      { base: 'Nj', letters: '\u01CB' },
      {
        base: 'O',
        letters:
          '\u004F\u24C4\uFF2F\u00D2\u00D3\u00D4\u1ED2\u1ED0\u1ED6\u1ED4\u00D5\u1E4C\u022C\u1E4E\u014C\u1E50\u1E52\u014E\u022E\u0230\u00D6\u022A\u1ECE\u0150\u01D1\u020C\u020E\u01A0\u1EDC\u1EDA\u1EE0\u1EDE\u1EE2\u1ECC\u1ED8\u01EA\u01EC\u00D8\u01FE\u0186\u019F\uA74A\uA74C',
      },
      { base: 'OI', letters: '\u01A2' },
      { base: 'OO', letters: '\uA74E' },
      { base: 'OU', letters: '\u0222' },
      { base: 'OE', letters: '\u008C\u0152' },
      { base: 'oe', letters: '\u009C\u0153' },
      {
        base: 'P',
        letters: '\u0050\u24C5\uFF30\u1E54\u1E56\u01A4\u2C63\uA750\uA752\uA754',
      },
      { base: 'Q', letters: '\u0051\u24C6\uFF31\uA756\uA758\u024A' },
      {
        base: 'R',
        letters:
          '\u0052\u24C7\uFF32\u0154\u1E58\u0158\u0210\u0212\u1E5A\u1E5C\u0156\u1E5E\u024C\u2C64\uA75A\uA7A6\uA782',
      },
      {
        base: 'S',
        letters:
          '\u0053\u24C8\uFF33\u1E9E\u015A\u1E64\u015C\u1E60\u0160\u1E66\u1E62\u1E68\u0218\u015E\u2C7E\uA7A8\uA784',
      },
      {
        base: 'T',
        letters:
          '\u0054\u24C9\uFF34\u1E6A\u0164\u1E6C\u021A\u0162\u1E70\u1E6E\u0166\u01AC\u01AE\u023E\uA786',
      },
      { base: 'TZ', letters: '\uA728' },
      {
        base: 'U',
        letters:
          '\u0055\u24CA\uFF35\u00D9\u00DA\u00DB\u0168\u1E78\u016A\u1E7A\u016C\u00DC\u01DB\u01D7\u01D5\u01D9\u1EE6\u016E\u0170\u01D3\u0214\u0216\u01AF\u1EEA\u1EE8\u1EEE\u1EEC\u1EF0\u1EE4\u1E72\u0172\u1E76\u1E74\u0244',
      },
      {
        base: 'V',
        letters: '\u0056\u24CB\uFF36\u1E7C\u1E7E\u01B2\uA75E\u0245',
      },
      { base: 'VY', letters: '\uA760' },
      {
        base: 'W',
        letters: '\u0057\u24CC\uFF37\u1E80\u1E82\u0174\u1E86\u1E84\u1E88\u2C72',
      },
      { base: 'X', letters: '\u0058\u24CD\uFF38\u1E8A\u1E8C' },
      {
        base: 'Y',
        letters:
          '\u0059\u24CE\uFF39\u1EF2\u00DD\u0176\u1EF8\u0232\u1E8E\u0178\u1EF6\u1EF4\u01B3\u024E\u1EFE',
      },
      {
        base: 'Z',
        letters:
          '\u005A\u24CF\uFF3A\u0179\u1E90\u017B\u017D\u1E92\u1E94\u01B5\u0224\u2C7F\u2C6B\uA762',
      },
      {
        base: 'a',
        letters:
          '\u0061\u24D0\uFF41\u1E9A\u00E0\u00E1\u00E2\u1EA7\u1EA5\u1EAB\u1EA9\u00E3\u0101\u0103\u1EB1\u1EAF\u1EB5\u1EB3\u0227\u01E1\u00E4\u01DF\u1EA3\u00E5\u01FB\u01CE\u0201\u0203\u1EA1\u1EAD\u1EB7\u1E01\u0105\u2C65\u0250',
      },
      { base: 'aa', letters: '\uA733' },
      { base: 'ae', letters: '\u00E6\u01FD\u01E3' },
      { base: 'ao', letters: '\uA735' },
      { base: 'au', letters: '\uA737' },
      { base: 'av', letters: '\uA739\uA73B' },
      { base: 'ay', letters: '\uA73D' },
      {
        base: 'b',
        letters: '\u0062\u24D1\uFF42\u1E03\u1E05\u1E07\u0180\u0183\u0253',
      },
      {
        base: 'c',
        letters:
          '\u0063\u24D2\uFF43\u0107\u0109\u010B\u010D\u00E7\u1E09\u0188\u023C\uA73F\u2184',
      },
      {
        base: 'd',
        letters:
          '\u0064\u24D3\uFF44\u1E0B\u010F\u1E0D\u1E11\u1E13\u1E0F\u0111\u018C\u0256\u0257\uA77A',
      },
      { base: 'dz', letters: '\u01F3\u01C6' },
      {
        base: 'e',
        letters:
          '\u0065\u24D4\uFF45\u00E8\u00E9\u00EA\u1EC1\u1EBF\u1EC5\u1EC3\u1EBD\u0113\u1E15\u1E17\u0115\u0117\u00EB\u1EBB\u011B\u0205\u0207\u1EB9\u1EC7\u0229\u1E1D\u0119\u1E19\u1E1B\u0247\u025B\u01DD',
      },
      { base: 'f', letters: '\u0066\u24D5\uFF46\u1E1F\u0192\uA77C' },
      {
        base: 'g',
        letters:
          '\u0067\u24D6\uFF47\u01F5\u011D\u1E21\u011F\u0121\u01E7\u0123\u01E5\u0260\uA7A1\u1D79\uA77F',
      },
      {
        base: 'h',
        letters:
          '\u0068\u24D7\uFF48\u0125\u1E23\u1E27\u021F\u1E25\u1E29\u1E2B\u1E96\u0127\u2C68\u2C76\u0265',
      },
      { base: 'hv', letters: '\u0195' },
      {
        base: 'i',
        letters:
          '\u0069\u24D8\uFF49\u00EC\u00ED\u00EE\u0129\u012B\u012D\u00EF\u1E2F\u1EC9\u01D0\u0209\u020B\u1ECB\u012F\u1E2D\u0268\u0131',
      },
      { base: 'j', letters: '\u006A\u24D9\uFF4A\u0135\u01F0\u0249' },
      {
        base: 'k',
        letters:
          '\u006B\u24DA\uFF4B\u1E31\u01E9\u1E33\u0137\u1E35\u0199\u2C6A\uA741\uA743\uA745\uA7A3',
      },
      {
        base: 'l',
        letters:
          '\u006C\u24DB\uFF4C\u0140\u013A\u013E\u1E37\u1E39\u013C\u1E3D\u1E3B\u017F\u0142\u019A\u026B\u2C61\uA749\uA781\uA747',
      },
      { base: 'lj', letters: '\u01C9' },
      {
        base: 'm',
        letters: '\u006D\u24DC\uFF4D\u1E3F\u1E41\u1E43\u0271\u026F',
      },
      {
        base: 'n',
        letters:
          '\u006E\u24DD\uFF4E\u01F9\u0144\u00F1\u1E45\u0148\u1E47\u0146\u1E4B\u1E49\u019E\u0272\u0149\uA791\uA7A5',
      },
      { base: 'nj', letters: '\u01CC' },
      {
        base: 'o',
        letters:
          '\u006F\u24DE\uFF4F\u00F2\u00F3\u00F4\u1ED3\u1ED1\u1ED7\u1ED5\u00F5\u1E4D\u022D\u1E4F\u014D\u1E51\u1E53\u014F\u022F\u0231\u00F6\u022B\u1ECF\u0151\u01D2\u020D\u020F\u01A1\u1EDD\u1EDB\u1EE1\u1EDF\u1EE3\u1ECD\u1ED9\u01EB\u01ED\u00F8\u01FF\u0254\uA74B\uA74D\u0275',
      },
      { base: 'oi', letters: '\u01A3' },
      { base: 'ou', letters: '\u0223' },
      { base: 'oo', letters: '\uA74F' },
      {
        base: 'p',
        letters: '\u0070\u24DF\uFF50\u1E55\u1E57\u01A5\u1D7D\uA751\uA753\uA755',
      },
      { base: 'q', letters: '\u0071\u24E0\uFF51\u024B\uA757\uA759' },
      {
        base: 'r',
        letters:
          '\u0072\u24E1\uFF52\u0155\u1E59\u0159\u0211\u0213\u1E5B\u1E5D\u0157\u1E5F\u024D\u027D\uA75B\uA7A7\uA783',
      },
      {
        base: 's',
        letters:
          '\u0073\u24E2\uFF53\u00DF\u015B\u1E65\u015D\u1E61\u0161\u1E67\u1E63\u1E69\u0219\u015F\u023F\uA7A9\uA785\u1E9B',
      },
      {
        base: 't',
        letters:
          '\u0074\u24E3\uFF54\u1E6B\u1E97\u0165\u1E6D\u021B\u0163\u1E71\u1E6F\u0167\u01AD\u0288\u2C66\uA787',
      },
      { base: 'tz', letters: '\uA729' },
      {
        base: 'u',
        letters:
          '\u0075\u24E4\uFF55\u00F9\u00FA\u00FB\u0169\u1E79\u016B\u1E7B\u016D\u00FC\u01DC\u01D8\u01D6\u01DA\u1EE7\u016F\u0171\u01D4\u0215\u0217\u01B0\u1EEB\u1EE9\u1EEF\u1EED\u1EF1\u1EE5\u1E73\u0173\u1E77\u1E75\u0289',
      },
      {
        base: 'v',
        letters: '\u0076\u24E5\uFF56\u1E7D\u1E7F\u028B\uA75F\u028C',
      },
      { base: 'vy', letters: '\uA761' },
      {
        base: 'w',
        letters:
          '\u0077\u24E6\uFF57\u1E81\u1E83\u0175\u1E87\u1E85\u1E98\u1E89\u2C73',
      },
      { base: 'x', letters: '\u0078\u24E7\uFF58\u1E8B\u1E8D' },
      {
        base: 'y',
        letters:
          '\u0079\u24E8\uFF59\u1EF3\u00FD\u0177\u1EF9\u0233\u1E8F\u00FF\u1EF7\u1E99\u1EF5\u01B4\u024F\u1EFF',
      },
      {
        base: 'z',
        letters:
          '\u007A\u24E9\uFF5A\u017A\u1E91\u017C\u017E\u1E93\u1E95\u01B6\u0225\u0240\u2C6C\uA763',
      },
    ];
    const diacriticsMap = {};
    for (let i = 0; i < defaultDiacriticsRemovalMap.length; i++) {
      const { letters } = defaultDiacriticsRemovalMap[i];
      for (let j = 0; j < letters.length; j++) {
        diacriticsMap[letters[j]] = defaultDiacriticsRemovalMap[i].base;
      }
    }
    return input.replace(/[^\u0000-\u007E]/g, (a) => diacriticsMap[a] || a);
  },
  difference (object, base) {
    function changes (object, base) {
      if (object === base) {
        return false;
      }
      if (!object || !base) {
        return true;
      }
      return _.transform(object, (result, value, key) => {
        if (!_.isEqual(value, base[key])) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    }
    return changes(object, base);
  },
  isBookingStatusCompleted (status) {
    const isFinished = finishedStatus.map((s) => s.code).includes(status);
    return isFinished;
  },
  paymentMappingCompletedToActive (paymentType) {
    paymentType = parseInt(paymentType);
    switch (paymentType) {
      case 0: {
        return 1;
      }
      case 1: {
        return 2;
      }
      case 2: {
        return 2;
      }
      case 3: {
        return 6;
      }
      case 4: {
        return 2;
      }
      case 5: {
        return 3;
      }
      case 6: {
        return 5;
      }
      case 7: {
        return 4;
      }
      case 8: {
        return 7;
      }
      case 9: {
        return 8;
      }
      case 10: {
        return 9;
      }
      case 12: {
        return 12;
      }
      case 13: {
        return 13;
      }
      case 14: {
        return 14;
      }
      case 16: {
        return 16;
      }
      case 17: {
        return 17;
      }
      case 20: {
        return 20;
      }
      case 21: {
        return 21;
      }
      case 23: {
        return 23;
      }
      case 24: {
        return 24;
      }
      case 25: {
        return 25;
      }
      case 26: {
        return 26;
      }
      default:
        return 1;
    }
  },
  paymentMappingActiveToCompleted (paymentType, isAllwayShowGoogleApplePay) {
    paymentType = parseInt(paymentType);
    switch (paymentType) {
      case 1: {
        return 0;
      }
      case 2: {
        return 1;
      }
      case 3: {
        return 5;
      }
      case 4: {
        return 7;
      }
      case 5: {
        return 6;
      }
      case 6: {
        return 3;
      }
      case 7: {
        return 8;
      }
      case 8: {
        return 9;
      }
      case 12: {
        return 12;
      }
      case 13: {
        return 13;
      }
      case 16: {
        return 16;
      }
      case 17: {
        return 16;
      }
      case 14: {
        return 0;
      }
      case 20: {
        return 20;
      }
      case 21: {
        return 21;
      }
      // Nếu Completed trên CC với apple, google pay thì fore về  cash
      case 9: {
        return isAllwayShowGoogleApplePay ? 10 : 0;
      }
      case 23: {
        return isAllwayShowGoogleApplePay ? 23 : 0;
      }
      case 25: {
        return 25;
      }
      case 26: {
        return 26;
      }
      default:
        return 0;
    }
  },
  isDisplayCancelNoShowAmount (booking) {
    if (
      [
        'canceled',
        'canceledByPassenger',
        'canceledByPartner',
        'canceledBymDispatcher',
        'canceledByCorpAd',
        'canceledByAPI',
        'canceledByWebBooking',
        'canceledByCC',
        'canceledByDriver',
        'noShow',
        'incident'
      ].includes(booking.status) &&
      booking.cancelInfo
    ) {
      return true;
    }
    return false;
  },
  isCompleteBooking (status) {
    return [
      'canceled',
      'canceledByPassenger',
      'canceledByPartner',
      'canceledBymDispatcher',
      'canceledByCorpAd',
      'canceledByAPI',
      'canceledByWebBooking',
      'canceledByCC',
      'canceledByDriver',
      'noShow',
      'completed',
      'incident',
      'completedWithoutService',
      'partialCompleted',
      'failed'
    ].includes(status);
  },
  mergeBooking (source, des) {
    if (!source || !des) {
      return source || des;
    }
    const book = _.mergeWith(source, des, (objValue, srcValue) => {
      if (_.isArray(objValue)) {
        return srcValue;
      }
    });
    if (des.drvInfo) {
      book.drvInfo = des.drvInfo;
    }
    if (des?.vehicle?.status === 'none') {
      book.vehicle = {
        status: 'none'
      }
    }
    book.request = _.merge(source.request, des.request);
    return { ...book };
  },
  getHourTimeLine (date, timezone) {
    // check if to day
    let today =
      moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD');
    today = false;
    const timeline = [];
    if (today) {
      // begin Creation timeline
      const currentTime = momenttz(date).tz(timezone).hours();
      if (currentTime) {
        for (let i = 0; i <= currentTime; i += 2) {
          if (i < 10) {
            timeline.push(`0${i}`);
          } else {
            timeline.push(`${i}`);
          }
        }
      }
    } else {
      for (let i = 0; i < 25; i += 2) {
        if (i < 10) {
          timeline.push(`0${i}`);
        } else {
          timeline.push(`${i}`);
        }
      }
    }
    return timeline;
  },
  getDateOfWeekTimeLine (input, timezone) {
    // let date = momenttz(input).tz(timezone)
    // //This method can be used to set the day of the week, with Sunday as 0 and Saturday as 6.
    // let dateOfWeek = date.day() // get day of week
    // if (dateOfWeek == 0) dateOfWeek = 7 // convert sunday to 7 for calculating easier

    // let timeline = []
    // for (let i = 1; i <= 7; i++) {
    //     switch (i) {
    //         case 1:
    //             timeline.push("Mon")
    //             break;
    //         case 2:
    //             timeline.push("Tue")
    //             break;
    //         case 3:
    //             timeline.push("Wed")
    //             break;
    //         case 4:
    //             timeline.push("Thu")
    //             break;
    //         case 5:
    //             timeline.push("Fri")
    //             break;
    //         case 6:
    //             timeline.push("Sat")
    //             break;
    //         case 7:
    //             timeline.push("Sun")
    //             break;
    //         default:
    //             break;
    //     }
    //     let today = moment().format('MM-DD-YYYY')
    //     let checkDate = date.date(i - 1).format('MM-DD-YYYY')
    //     console.log(today, checkDate)
    //     if (today == checkDate) break;
    // }
    return ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  },
  getDayOfMonthTimeLine (input, tz) {
    const month = momenttz(input).tz(tz).month();
    const year = momenttz(input).tz(tz).year();
    let monthLabel = month + 1;
    if (monthLabel < 10) monthLabel = `0${monthLabel}`;
    const timeline = [];
    let checkDate = momenttz(input).tz(tz).date(1); // set check date is beginning of month
    let dateInMonth = 1;
    while (checkDate.month() == month && dateInMonth <= 31) {
      const timeToAdd =
        dateInMonth < 10
          ? `${monthLabel}/0${dateInMonth}/${year}`
          : `${monthLabel}/${dateInMonth}/${year}`;
      timeline.push(timeToAdd);
      dateInMonth++;
      checkDate = checkDate.add(1, 'days');
    }
    return timeline;
  },
};

export const calculatorFleetORAdditionalService = (services, subTotal, bookCompleted = {}) => {
  let extraFee = 0;
  services?.forEach((service) => {
    if (service.active || service.serviceType === 'Compulsory') {
      // only for fleetService(new setting)
      if (
        service?.infoService?.serviceFeeType === FEE_TYPE.Percent &&
        subTotal > 0 &&
        !STATUS_FLEET_SERVICE.includes(bookCompleted?.status)
      ) {
        if (service?.infoService?.serviceFeePercent > 0) {
          extraFee +=
            (service?.infoService?.serviceFeePercent * subTotal) / 100;
        }
        return;
      }

      extraFee += service.fee;
      return;
    }
  });
  return extraFee;
};

export const calFleetORAdditionalServicePayment = (services, subTotal) => { // completed payment form
  let extraFee = 0;
  services?.forEach((service) => {
    if (service.active) {
      // only for fleetService(new setting)
      if (
        service?.serviceFeeType === FEE_TYPE.Percent &&
        subTotal > 0
      ) {
        if (service?.serviceFeePercent > 0) {
          extraFee +=
            (service?.serviceFeePercent * subTotal) / 100;
        }
        return;
      }

      extraFee += service.fee
      return;
    }
  });
  return extraFee;
};

export const getTaxFleetService = (
  services = [],
  isNewSettingAdditionFee,
  fareSettings,
  subTotal
) => {
  if (
    isNewSettingAdditionFee &&
    fareSettings?.fare?.taxActive &&
    services?.length > 0
  ) {
    let taxFleetService = 0;
    const taxValueSetting = fareSettings?.fare?.tax || 0;
    services.map((sv) => {
      if (
        ((sv && sv.active) || sv.type === 'Compulsory') &&
        (sv.applyTax || sv.infoService?.applyTax)
      ) {
        if (
          sv.serviceFeeType === FEE_TYPE.Percent ||
          sv.infoService?.serviceFeeType === FEE_TYPE.Percent
        ) {
          if (subTotal > 0) {
            taxFleetService +=
              (taxValueSetting * ((sv.serviceFeePercent * subTotal) / 100)) /
              100;
          }
        } else {
          taxFleetService += (taxValueSetting * sv.fee) / 100;
        }
      }
    });
    return taxFleetService;
  }
  return 0;
};

export const checkForceRateType = (data = {}, oldTypeRate, disableHourlyRate) => {
  const supportedRates = data.supportedRates || {};
  let newDuration = oldTypeRate;
  // truong hop dang chon hourly
  if (
    oldTypeRate &&
    !supportedRates.hourly && // Nhưng service mới không support hourly
    (supportedRates.regular || supportedRates.flatFare)
  ) {
    newDuration = false;
  }
  
  // truong hop dang chon Regular or Flat
  if (
    !oldTypeRate &&
    !supportedRates.regular &&
    !supportedRates.flatFare &&
    supportedRates.hourly
    ) {
    newDuration = true;
  }
  // truong hop vua co flat vua co hourly và fleet bật setting force flat
  if(supportedRates.hourly 
    && supportedRates.flatFare
    && disableHourlyRate
  ) {
    newDuration = false
  }
  return newDuration;
};

export const getNameAffiliateCarType = (bookInfo) => {
  let name = ''
  const { thirdParty, vehicleType } = bookInfo?.externalInfo || {}
  if(thirdParty === thirdPartyIntegration.bookingAPI) {
    name = AFFILIATE_BOOKING_CAR_TYPE.find(
      (vh) => vh.key === vehicleType
    )?.title || '';
  }
  if(thirdParty === thirdPartyIntegration.holidaytaxis) {
    name = vehicleType
  }
  return name
}

export const filterCarTypeTransport = (cars = []) => {
  if(!cars || cars.length === 0) return []
  return cars.filter(
    (ob) =>
      !ob.shuttle &&
      !ob.delivery &&
      !ob.superHelper &&
      !ob.food &&
      !ob.mart &&
      !ob.intercity
  );
}

export function isValidHttpsUrl(url) {
  const httpsRegex = /^https:\/\//i;
  const infoErrInvalid = {
    valid: false,
    msgErr: 'invalidURL',
  };
  try {
    const parsedUrl = new URL(url);
    const domainRegex = /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,6}$/i;
    // check domain name
    if (!domainRegex.test(parsedUrl.host)) {
      return infoErrInvalid;
    }
    // check https
    if (!httpsRegex.test(url)) {
      return {
        valid: false,
        msgErr: 'notUsingHTTPS',
      };
    }

    return {
      valid: true,
    };
  } catch (error) {
    return infoErrInvalid;
  }
}

// check reset cartype when get new service 
export const checkResetCarWhenGetNewService = (
  newVehicleTypes = [], 
  vehicleTypeSelecting,
  is3rdBooking,
  isShuttle
) => {
  let hasReset = false,
      newVehicleSelected = null;

  // filter cartype based bookType and rate
  if (isShuttle) {
    newVehicleTypes = newVehicleTypes.filter((ob) => ob.shuttle);
  } else {
    newVehicleTypes = filterCarTypeTransport(newVehicleTypes);
  }

  if (newVehicleTypes?.length > 0) {
    const vehicleTypeSelectingInfo = newVehicleTypes.find((car) => {
      return (
        car.vehicleType == vehicleTypeSelecting
      );
    });
    if ((vehicleTypeSelectingInfo || is3rdBooking) && vehicleTypeSelecting) {
      newVehicleSelected = vehicleTypeSelecting
    } else {
      // new service no have vehicle selecting
      hasReset = true
      newVehicleSelected = newVehicleTypes[0];
    }
  } else {
    // new service dont support vehicle 
    hasReset = true;
    newVehicleSelected = null
  }
  return {
    hasReset,
    newVehicle: newVehicleSelected
  }
}

// check fore cartype khi không được assign rate
export const checkForceCarTypeSelecting = (
  newService = {}, 
  oldCarType, 
  notShowCarTypeCCWithoutRate = false,
  isShuttle = false
) => {
  if(!notShowCarTypeCCWithoutRate) return oldCarType
  if(_.isEmpty(newService) || _.isEmpty(oldCarType)) return {}
  let vehicleType = newService && newService.vehicleType || []
  if (isShuttle) {
    vehicleType = vehicleType.filter((ob) => ob.shuttle === isShuttle)
  } else {
    vehicleType = filterCarTypeTransport(vehicleType)
  }
  let newCartype = oldCarType
  if(vehicleType && vehicleType.length > 0) {
    const cartypeSelecting = vehicleType.find(vhc => vhc._id === oldCarType._id)
    if(!cartypeSelecting) return {}
    // nếu cartype đang chọn không support bất cứ rate nào
    if (cartypeSelecting 
      && cartypeSelecting.supportedRates
      && !Object.values(cartypeSelecting.supportedRates).some(val => val)
    ) {
      // thì force car type tiếp theo có assign rate 
      newCartype = vehicleType.find(vhc => {
        const supportedRates = vhc.supportedRates || {}
        return Object.values(supportedRates).some(val => val)
      })
      if(!newCartype) return {}
      return newCartype
    }
    return cartypeSelecting
  } else {
    return {}
  }
}

export const calculatorAmountDue = (
  bookInfo,
  isBookingCompleted,
  farmOut,
  fleetMarkup = 0,
  farmIn
) => {
  if (farmIn) {
    return 0
  }
  if (isBookingCompleted) {
    let totalCompleted = bookInfo?.completedInfo?.total || 0;
    return totalCompleted - (bookInfo?.paidAmount || 0);
  }
  // book active
  const { fare } = bookInfo?.request?.estimate;
  let etaFare = (fare?.etaFare || 0) + (fare?.markupDifference || 0);
  if (farmOut) {
    return (fleetMarkup || 0) + (fare?.qupSellPrice || 0);
  }
  return etaFare - (bookInfo?.paidAmount || 0);
};

export const checkShowChargeInvoicingBtn = (bookInfo, is3rdBooking, isApiDemandBooking, isSupplierPermission) => {
  if (bookInfo.invoiceId || is3rdBooking || isApiDemandBooking || isSupplierPermission) return false;
  // trường hợp book đã complete thì chỉ cần có nợ là show
  if (CCLiteCommonFunc.isBookingStatusCompleted(bookInfo.status))
    return [PAID_STATUS.pending, PAID_STATUS.partial].includes(
      bookInfo.paidStatus
    );

  // trường hợp book active thì phải kiểm tra thêm payment method là direct invoice
  if (checkPaymentDirectInvoicing(bookInfo)) return true;
  return false;
};

export const checkPaymentDirectInvoicing = (bookInfo = {}) => {
  const paymentType = bookInfo.request?.paymentType
  return paymentType === 5
}

export const checkShowPendingPaymentCompleteBooking = (paymentComplete) => {
  if(paymentComplete?.paymentType === 5) return false; // hide when payment is direct invoicing
  return true
}

export const checkShowViewPaymentLogsBtn = (bookInfo = {}, is3rdBooking, isSupplierPermission) => {
  if (is3rdBooking || isSupplierPermission) return false;
  return true; // SL-33317
};

export const checkShowInfoPrePaid = (bookInfo = {}, is3rdBooking, isApiDemandBooking) => {
  if(is3rdBooking || isApiDemandBooking) return false
  return checkPaymentDirectInvoicing(bookInfo) || CCLiteCommonFunc.isBookingStatusCompleted(bookInfo.status)
}

export const checkListBookingCanAssignSuplier = (books = [], auth = {}) => {
  if(!books || books.length === 0) return false
  return books.every(bk => checkCanAssignSuplier(bk, auth))
}

export const checkListBookingCanGroupOrder = (books = [], auth) => {
  if(!books || books.length === 0) return false
  return books.every(bk => checkBookingCanGroupOrder(bk, '', auth))
}

export const getPermissionCompanyId = (auth) => {
  return auth.user?.roles?.companyId || ''
}

export const checkListBookingCanAssignDriver = (books = []) => {
  if(!books || books.length === 0) return false
  return books.every(bk => checkCanAssignDriver(bk))
}

export const checkListBookingCanDetachDriver = (books = []) => {
  if(!books || books.length === 0) return false
  return books.every(bk => checkCanDetachDriver(bk))
}

export const checkListBookingCanUnassignVehicle = (books = []) => {
  if(!books || books.length === 0) return false
  return books.some(bk => checkCanUnassignVehicle(bk))
}

export const getVehicleAndDriverOfBooks = (books = []) => {
  let vhcType = [],
      drvs = [],
      vhc = [],
      vhcTypeAssociate = [];
    books.map((bk) => {
      if (bk.request.vehicleTypeRequest)
        vhcType.push(bk.request.vehicleTypeRequest);
      if (bk.drvInfo?.userId) drvs.push(bk.drvInfo.userId);
      if (bk.vehicle?.vehicleId) vhc.push(bk.vehicle);
      if (bk.request?.vehicleType?.length > 0) vhcTypeAssociate = vhcTypeAssociate.concat(bk.request?.vehicleType);
    });
    return { vhcType, vhc, drvs, vhcTypeAssociate: _.uniq(vhcTypeAssociate) }
}

export const checkShowUnassignVehicleBtn = (books = []) => {
  const bookType = getBookingType(books?.[0]),
    pickupTime = books?.[0]?.request?.pickUpTime || 'Now';
  // don't detach with book now
  if (
    books.length === 1 &&
    (bookType === BOOK_TYPE.now ||
      (bookType === BOOK_TYPE.rideSharing && pickupTime === 'Now'))
  ) {
    return false;
  }

  const { vhc } = getVehicleAndDriverOfBooks(books)

  if (!vhc || vhc.length === 0)
    return false;

  return books.some(bk => checkCanUnassignVehicle(bk))
}

export const checkListBookingCanOfferDriver = (books = []) => {
  if(!books || books.length === 0) return false
  return books.every(bk => checkCanOfferDriver(bk))
}

export const getCompanyOfOperator = (auth, companies) => {
  if(!auth ||  !companies) return ''
  const { user } = auth;
  const isPermissionCompany = !!user.roles?.companyId;
  let companyOfOperator = null;
  if (isPermissionCompany) {
    companyOfOperator = _.find(
      companies,
      (cp) => cp._id === user.roles?.companyId
    );
    return companyOfOperator
  }
  return null
}

export const getSuppilerPermission = (auth) => {
  return !!auth?.user?.roles?.supplierPermission
}

export const checkCanRemoveLastOptionCorporate = (newArrOption, selectingOption, user, company) => {
  if (
    newArrOption?.length === 0 &&
    selectingOption?.action === 'remove-value' &&
    checkCorpHasCustomeSupplier(user, company)
  ) {
    return false
  }
  return true
}

export const filterZonesBasedPermission = (auth, allZone = [], allCompany) => {
  if (auth?.user.roles?.companyId) {
    const zoneOfCompanyUser = _.get(
      getCompanyOfOperator(auth, allCompany),
      'operationZone',
      []
    );
    if (zoneOfCompanyUser?.length > 0) {
      return allZone?.filter((zone) => zoneOfCompanyUser.includes(zone._id) && zone.isActive && zone.display);
    }
  }
  return allZone;
};

export const checkAllSupplierHasSupportPickup = (result, auth, companies) => {
  // only check with case user: company permission and corporate user
  const zonePickup = result?.res?.zonesLocal?.[0]?._id;
  if(zonePickup) {
    const userCompany = getCompanyOfOperator(auth, companies)
    // case company not support pickup zone
    if (
      userCompany?._id &&
      userCompany.operationZone?.length > 0 &&
      !userCompany.operationZone.includes(zonePickup)
    ) {
      return false
    }

    // case user coporate has custome company but all company do not support zone
    if (
      checkCorpHasCustomeSupplier(auth.user, auth?.user?.corporateAccount) &&
      !checkSomeSupplierSupportZone(zonePickup, auth?.user?.corporateAccount?.supplier?.value, companies)
    ) {
      return false
    }

  }
  return true
}

export const checkResetSupplierByZone = (
  newZoneId,
  auth,
  supplierCompanies,
  commonData,
  compannyAvailable,
  bookingDetail
) => {
  let result = {
    hasReset: false,
    suppliers: supplierCompanies
  }
  const companyOfOperator = getCompanyOfOperator(auth, [...commonData.companies, ...commonData.suppliers]);
  if (companyOfOperator?._id) { // user company permission
    return [companyOfOperator?._id];
  }
  if (checkCorporateUser(auth.user)) { // user corporate
    if (checkCorpHasCustomeSupplier(auth.user, auth.user?.corporateAccount)) {
      if (
        checkEverySupplierSupportZone(
          newZoneId,
          supplierCompanies,
          [...commonData.companies, ...commonData.suppliers],
          bookingDetail
        )
      ) {
        return result
      } else {
        result.hasReset = true
        result.suppliers = [compannyAvailable?.[0]?._id]; // nếu user corporate and custome supplier thi ...
        return result
      }
    }
    if (bookingDetail?.operator?.isAdmin) {
      return result
    }
  }
  if ( // user admin
    bookingDetail?.request?.pickup?.zoneId === newZoneId &&
    supplierCompanies?.length > 0
  ) {
    return result;
  }
  return result;
};

export const checkSomeSupplierSupportZone = (zone, supplierIDs, companyFleet) => {
  const suppliersInfo = companyFleet?.filter((c) =>
    supplierIDs?.includes(c._id)
  );
  return _.some(suppliersInfo, (s) => {
    if (
      !s.operationZone || // old company
      s.operationZone.length === 0 || // old company
      s.operationZone.includes(zone) // supplier must support zone of all bookings
    ) {
      return true;
    }
    return false;
  });
}

export const checkEverySupplierSupportZone = (zone, supplierIDs, companyFleet, bookInfo = {}) => {
  const suppliersInfo = companyFleet?.filter((c) =>
    supplierIDs?.includes(c._id)
  );
  const everySupport = _.every(suppliersInfo, (s) => {
    if (
      !s.operationZone || // old company
      s.operationZone.length === 0 || // old company
      s.operationZone.includes(zone) // supplier must support zone of all bookings
    ) {
      return true;
    }
    return false;
  });
  if(bookInfo?.operator?.isAdmin) {
    return true
  }
  return everySupport
}

export const checkDisableSupplierSupportZone = (zone, supplierIDs, companyFleet) => {
  const suppliersInfo = companyFleet?.filter((c) =>
    supplierIDs?.includes(c._id)
  );
  return !_.every(suppliersInfo, (s) => {
    if (
      !s.operationZone || // old company
      s.operationZone.length === 0 || // old company
      s.operationZone.includes(zone) // supplier must support zone of all bookings
    ) {
      return true;
    }
    return false;
  });
}

export const checkSupplierSupportZonePickup = (
  companyOfOperator,
  book
) => {
  if(companyOfOperator?.operationZone?.length > 0) {
    return companyOfOperator?.operationZone?.includes(book?.request?.pickup?.zoneId)
  }
  return true;
};

export const checkCorpHasCustomeSupplier = (user, corporate) => {
  const supplierType = _.get(corporate, 'supplier.type', '');
  return checkCorporateUser(user) &&
  supplierType === SUPPLIER_TYPE.customized
}

export const checkCanAssignDriverWhenOneBook = (bookInfo = {}) => {
  if(getBookingType(bookInfo) === BOOK_TYPE.now) {
    // Assign 1 book then support book now
    return  STATUS_ASSIGN_DRIVER.includes(bookInfo.status)
  }
  return checkCanAssignDriver(bookInfo)
};

export const checkCanDetachDriver = (bookInfo = {}, typeBookingGroup) => {
  const bookType = getBookingType(bookInfo),
    bookStatus = bookInfo.status,
    typeGroup = getBookingTypeGroup(bookInfo);

  // Only select same book type
  if(typeBookingGroup && typeGroup !== typeBookingGroup) return false

  // book Transport
  if (
    [BOOK_TYPE.reservation, BOOK_TYPE.shuttle].includes(bookType)
  ) {
    return ['pre', 'pending', 'confirmed', 'booked', 'arrived'].includes(
      bookStatus
    );
  }

  // book intercity and Delivery
  if (
    [BOOK_TYPE.intercity, BOOK_TYPE.delivery, BOOK_TYPE.batchDelivery].includes(
      bookType
    )
  ) {
    return ['confirmed', 'confirmed_delivery'].includes(bookStatus);
  }

  return false;
};

export const getCommonParamMapProxy = (auth) => {
  
}

export const checkCanUnassignVehicle = (bookInfo = {}) => {
  const bookType = getBookingType(bookInfo),
      bookStatus = bookInfo.status
    // don't detach with book now
    if(bookType !== BOOK_TYPE.reservation && bookType !== BOOK_TYPE.rideSharing) {
      return false
    }
    return ['pre', 'pending', 'confirmed', 'action', 'booked', 'arrived'].includes(
      bookStatus
    );
};

export const checkCanAssignDriver = (bookInfo = {}, typeBookingGroup) => {
  const bookType = getBookingType(bookInfo),
    bookStatus = bookInfo.status,
    typeGroup = getBookingTypeGroup(bookInfo)
  
  // Only select same book type
  if(typeBookingGroup && typeGroup !== typeBookingGroup) return false

  // book Transport
  if ([BOOK_TYPE.reservation, BOOK_TYPE.shuttle, BOOK_TYPE.rideSharing, BOOK_TYPE.now].includes(bookType)) {
    return STATUS_ASSIGN_DRIVER.includes(bookStatus);
  }

  // book intercity
  if (BOOK_TYPE.intercity === bookType) {
    return ['confirmed', 'confirmed_delivery'].includes(bookStatus);
  }

  // book  Delivery
  if ([BOOK_TYPE.delivery, BOOK_TYPE.batchDelivery].includes(bookType)) {
    return [
      'confirmed',
      'pre',
      'pending',
      'confirmed_delivery',
      'delivery_booked',
      'delivery_arrived',
      'action'
    ].includes(bookStatus);
  }
  return false;
};

export const checkCanOfferDriver = (bookInfo = {}, typeBookingGroup) => {
  const bookType = getBookingType(bookInfo),
    bookStatus = bookInfo.status,
    typeGroup = getBookingTypeGroup(bookInfo)

  if (
    bookType === BOOK_TYPE.now ||
    bookInfo.groupId // hide with group booking
  ) return false;

  // Only select same book type
  if(typeBookingGroup && typeGroup !== typeBookingGroup) return false

  // book Transport
  if ([BOOK_TYPE.reservation, BOOK_TYPE.rideSharing].includes(bookType)) {
    return statusCanOfferDriver(bookStatus);
  }

  // book  Delivery
  if ([BOOK_TYPE.delivery, BOOK_TYPE.batchDelivery].includes(bookType)) {
    return statusCanOfferDriver(bookStatus);
  }
  return false;
};

export const checkCanAssignSuplier = (bookInfo = {}, auth, typeBookingGroup) => {
  const typeGroup = getBookingTypeGroup(bookInfo);
  if (
    (bookTypeCanAssignSuplier(bookInfo) && auth.user?.roles?.supplierId) || // operater is user company
    (typeBookingGroup && typeGroup !== typeBookingGroup) || // Only select same book type
    typeGroup === TYPE_BOOKING_GROUP.delivery ||
    typeGroup === TYPE_BOOKING_GROUP.intercity
  )
    return false;
  const isReservation = _.get(bookInfo, 'request.pickUpTime', 'Now') !== 'Now';

  const corporateAccount = auth.user?.corporateAccount || {};
  let assignCorporateBoard = true; // check disable với booking được tạo với owner mà có supplier không được support ở corp
  if (
    checkCorpHasCustomeSupplier(auth.user, corporateAccount) &&
    bookInfo.supplierCompanies?.length > 0 &&
    !_.isEmpty(
      _.differenceWith(
        bookInfo.supplierCompanies,
        corporateAccount?.supplier?.value
      )
    )
  )
    assignCorporateBoard = false;
  
  return STATUS_ASSIGN_SUPLIERS.includes(bookInfo.status) 
    && isReservation 
    && assignCorporateBoard;
};

export const checkCanAssignSuplierWhenOneBook = (bookInfo = {}) => {
  return (
    STATUS_ASSIGN_SUPLIERS.includes(bookInfo.status) &&
    bookTypeCanAssignSuplier(bookInfo)
  );
};

export const bookTypeCanAssignSuplier = (bookInfo = {}) => {
  const bookType = getServiceType(bookInfo)
  return bookType !== BOOK_TYPE.intercity &&
  bookType !== BOOK_TYPE.delivery &&
  bookType !== BOOK_TYPE.batchDelivery

}

export const checkCorporateUser = (user) => {
  if (
    user.userType == userType.CorporateAdmin ||
    user.userType == userType.CorporateUser
  ) {
    return true;
  }
  return false
}

export const isBookingReservation = (bookInfo = {}) => {
  return _.get(bookInfo, 'request.pickUpTime', 'Now') !== 'Now'
}

// Check book can group
export const checkBookingCanGroupOrder = (bookInfo = {}, typeBookingGroup = '', auth) => {
  if(_.isEmpty(bookInfo)) return false
  const { puPoints = [], doPoints = []} = bookInfo
  const rideSharing = _.get(bookInfo, 'request.rideSharing', false)
  const isReservation = _.get(bookInfo, 'request.pickUpTime', 'Now') !== 'Now'
  const groupId = bookInfo.groupId || ''
  const statusCanGroupBooking = STATUS_GROUP_BOOKING.includes(bookInfo.status),
    typeGroup = getBookingTypeGroup(bookInfo);

  // Only select same book type
  if(typeBookingGroup && typeGroup !== typeBookingGroup) return false
  // truong hop delivery booking
  if(isParcelDeliveryBooking(bookInfo)) {
    const batchDelivery = _.get(auth, 'selectedFleet.batchDelivery', false)
    return batchDelivery 
    && rideSharing 
    && isReservation 
    && !groupId 
    && statusCanGroupBooking
    && (typeBookingGroup === TYPE_BOOKING_GROUP.delivery || !typeBookingGroup)
  }
  
  // truong hop transport booking
  const groupBooking = _.get(auth, 'selectedFleet.groupBooking', false)
  return groupBooking 
    && rideSharing 
    && isReservation 
    && !groupId 
    && statusCanGroupBooking
    && puPoints.length === 1 && doPoints.length === 1
    && (typeBookingGroup === TYPE_BOOKING_GROUP.tranpsort || !typeBookingGroup)
}
export const checkBookingPrePaided = (bookInfo = {}) => {
  const { paidStatus } = bookInfo || {}
  return paidStatus === PAID_STATUS.paid ||  paidStatus === PAID_STATUS.partial
}

export const filterCarTypeWithoutAssignRate = (vhcList = [], corporate, isShuttle = false) => {
  if (isShuttle) {
    vhcList = vhcList.filter((ob) => ob.shuttle === isShuttle)
  } else {
    vhcList = filterCarTypeTransport(vhcList)
  }
  // Không show carType nếu chưa assign bất kì rate nào
  if (corporate && 
    corporate._id &&
    corporate.pricing && corporate.pricing.differentRate
  ) {
    vhcList = vhcList.filter((ob) => {
      if (ob.corpRates && ob.corpRates.length > 0) {
        return (
          ob.corpRates.some(
            (corpRate) => {
              return corpRate.corporateId === corporate._id
            }
          )
        );
      }
      return false;
    });
  } else {
    vhcList = vhcList.filter((ob) => ob.rates && ob.rates.length > 0);
  }
  // Lọc Xe không được assign Rate tại zone Pickup đang chọn
  if (vhcList && vhcList.length > 0) {
    vhcList = vhcList.filter((ob) => {
      if (!_.isEmpty(ob.supportedRates)) {
        if (
          ob.supportedRates.hourly ||
          ob.supportedRates.regular ||
          ob.supportedRates.flatFare
        )
          return true;
        return false;
      }
      return false;
    });
  }
  return vhcList;
};

export const isParcelDeliveryBooking = (booking = {}) => {
  if(!booking || _.isEmpty(booking)) return false
  return booking.delivery && booking.jobType === 'delivery'
}

export const checkFleetEnablePWA = (fleetSelected) => {
  const modulesEnable = fleetSelected?.modules || []
  return modulesEnable.some(mdl => mdl.name === "PWA")
}

export const MultiSelectCommonFunc = {
  getSelectValues (e) {
    const { options } = e.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    return value;
  },
};

export const handleResultAddCard = (url, callback) => {
  if(url) {
    window.open(url, '_blank').focus();
    socketDispatchApi.remove(socketConfigs.receive.registeredCard);
    socketDispatchApi.on(socketConfigs.receive.registeredCard, data => {
      if(data && data.returnCode === 200) {
        console.log(data.credit)
        if(callback) callback(data.credit)
        this.context.notification(
          'success',
          I18n.t('customer.Add_card_success')
        )
      } else {
        this.context.notification('error', I18n.t(`messages.credits.error`));
      }
    });
  }
}

export function handleResultUpdateFinishedBooking(res) {
  if (res && res.ok && res.success) {
    this.context.notification(
      'success',
      'Booking has been updated successfully'
    );
    this.close();
  } else {
    let msg = 'Can not update booking'
    if(res.errorCode === 'EXTERNAL_ID_EXISTED') {
      msg = I18n.t('messages.booking.EXTERNAL_ID_EXISTED');
    }
    this.context.notification('error', msg);
  }
}

export const parserIntNumberInString = (text) => {
  if(!text) return 0
  return (text.split(' ') || [])
    .map(character => {
      if(parseInt(character)) return parseInt(character)
      return character
    })
    .join(' ')
    .trim()
}

export const convertToDifferentSurchage = (data, isDistanceDiversity, currencyISO) => {
  let newSurcharge = {
    surchargeByServices: [
      {
        typeRate: "regular",
        value: 0
      },
      {
        typeRate: "flat",
        value: 0
      },
      {
        typeRate: "hourly",
        value: 0
      },
      {
        typeRate: "shuttle",
        value: 0
      },
      {
        typeRate: "carHailing",
        value: 0
      }
    ],
    distanceRange: [
      {
        from: 0,
        to: Number.MAX_SAFE_INTEGER,
        surchargeByServices: [
          {
            typeRate: "regular",
            value: 0
          },
          {
            typeRate: "flat",
            value: 0
          },
          {
            typeRate: "hourly",
            value: 0
          },
          {
            typeRate: "shuttle",
            value: 0
          },
          {
            typeRate: "carHailing",
            value: 0
          }
        ]
      }
    ]
  }
  newSurcharge.currencyISO = currencyISO
  if (isDistanceDiversity) {
    const newDistanceRange = data[0] && data[0].distanceRange || []
    newSurcharge.distanceRange = newDistanceRange.map((ob) => {
      return {
        from: ob.from,
        to: ob.to,
        surchargeByServices: newSurcharge.surchargeByServices.map((dt) => {
          return {
            typeRate: dt.typeRate,
            value: ob.surchargeValue
          }
        })
      }
    })
    return [newSurcharge]
  } else {

    const valueSurchage = data[0] && data[0].value || 0
    newSurcharge.surchargeByServices = newSurcharge.surchargeByServices.map((ob) => {
      return {
        typeRate: ob.typeRate,
        value: valueSurchage
      }
    })
    return [newSurcharge]
  }
}

export const checkMultiPuDoNotEmpty = (puPoint = [], doPoint = []) => {
  if (puPoint.length === 0 || doPoint.length === 0) return false;
  if (puPoint.length === 1 && doPoint.length === 1) return false;
  if (puPoint.length > 1 || doPoint.length > 1) return true;
  return false;
};

export function formatPhoneNumber (text) {
  if (Validation.isStringEmpty(text)) return '';
  return text
    .replace(/[a-zA-Z`~!@#$ %^&*()_=\\|{};:"'></?,.-]/g, '')
    .replace(/(?!^)\+/g, '');
}

export function formatMMYY (text) {
  if (Validation.isStringEmpty(text)) return '';
  if (text.length == 2) {
    if (text[1] == '/') {
      if (text[0] == '0') return '0';
      return `0${text[0]} / `;
    }
  }
  if (text.length >= 7) return text.substr(0, 7);
  text = text.replace(/[a-zA-Z`~!@#$%^&*()_=\\|{};:"'></?,.-]/g, '');
  if (text.length > 0) {
    if (text.length == 1) {
      if (text == '1') return text;
      if (text == '0') return text;
      return `0${text} / `;
    }
    if (text.length == 2) {
      if (parseInt(text) > 12) {
        let textArray = text.split('');
        textArray = textArray.filter((c) => c != ' ');
        textArray.splice(1, 0, ' / ');
        return `0${textArray.join('')}`;
      }
      return `${text} / `;
    }
    let textArray = text.split('');
    textArray = textArray.filter((c) => c != ' ');
    textArray.splice(2, 0, ' / ');
    return textArray.join('').substr(0, 7);
  }
  return text.substr(0, 7);
}

export function formatMMYYYY (text) {
  if (Validation.isStringEmpty(text)) return '';
  if (text.length == 2) {
    if (text[1] == '/') {
      if (text[0] == '0') return '0';
      return `0${text[0]} / `;
    }
  }
  if (text.length >= 9) return text.substr(0, 9);
  text = text.replace(/[a-zA-Z`~!@#$%^&*()_=\\|{};:"'></?,.-]/g, '');
  if (text.length > 0) {
    if (text.length == 1) {
      if (text == '1') return text;
      if (text == '0') return text;
      return `0${text} / `;
    }
    if (text.length == 2) {
      if (parseInt(text) > 12) {
        let textArray = text.split('');
        textArray = textArray.filter((c) => c != ' ');
        textArray.splice(1, 0, ' / ');
        return `0${textArray.join('')}`;
      }
      return `${text} / `;
    }
    let textArray = text.split('');
    textArray = textArray.filter((c) => c != ' ');
    textArray.splice(2, 0, ' / ');
    return textArray.join('');
  }
  return text;
}

export function formatMMDDYYYY (text) {
  const dilime = '/';
  if (Validation.isStringEmpty(text)) return '';
  if (text.length == 2) {
    if (text[1] == '/') {
      if (text[0] == '0') return '0';
      return `0${text[0]}${dilime}`;
    }
  }
  if (text.length >= 10) return text.substr(0, 10);
  text = text.replace(/[a-zA-Z`~!@#$%^&*()_=\\|{};:"'></?,.-]/g, '');
  if (text.length > 0) {
    if (text.length == 1) {
      if (text == '1') return text;
      if (text == '0') return text;
      return `0${text}${dilime}`;
    }
    if (text.length == 2) {
      if (parseInt(text) > 12) {
        let textArray = text.split('');
        textArray = textArray.filter((c) => c != ' ');
        textArray.splice(1, 0, dilime);
        return `0${textArray.join('')}`;
      }
      return text;
    }
    if (text.length == 4) {
      const mmStr = text.substring(2);
      if (parseInt(mmStr) > 31) {
        return text.substring(0, 2) + dilime + maxDay(text.substring(0, 2));
      }
      const textArray = text.split('');
      textArray.splice(2, 0, dilime);
      return textArray.join('') + dilime;
    }
    let textArray = text.split('');
    textArray = textArray.filter((c) => c != ' ');
    textArray.splice(2, 0, dilime);
    if (textArray.length >= 5) textArray.splice(5, 0, dilime);
    return textArray.join('');
  }

  return text;
}

function maxDay (month) {
  if (month == '02') return '28';
  if (month == '04' || month == '06' || month == '09' || month == '11')
    return '30';
  return '31';
}

export function trimPhoneNumber (phone, noPlus) {
  if (phone) {
    const newPhone = phone
      .replace(new RegExp(' ', 'g'), '')
      .replace(new RegExp('-', 'g'), '');
    if (noPlus) {
      return newPhone.replace(/\+/g, '');
    }
    return newPhone;
  }
  return '';
}

export function removeEmojis (text) {
  if (Validation.isStringEmpty(text)) return '';
  var regex =
    /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
  return text.replace(regex, '');
}

// const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// const re = /^(?=[^@]*[A-Za-z])([a-zA-Z0-9])(([a-zA-Z0-9])*([\._-])?([a-zA-Z0-9]))*@(([a-zA-Z0-9\-])+(\.))+([a-zA-Z]{2,4})+$/i;
const reEmail =
  /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
export const Validation = {
  validateUsername (username) {
    const re = /^[a-z0-9]{3,20}$/g;
    return re.test(username.trim());
  },

  validateEmail (email, isRequire = false) {
    if ((!email || email.length == 0) && !isRequire) {
      return true;
    }
    if (!email || email.length == 0) {
      return true;
    }
    return reEmail.test(email);
  },

  validateMultiEmail (emails) {
    const emailArray = emails.split(';');
    let validator = true;
    emailArray.forEach((email) => {
      if (!reEmail.test(email.trim())) {
        validator = false;
      }
    });
    return validator;
  },

  validatePassword (password) {
    const re = /^(?=.*[0-9])(?=.*[!@#$%^&*.])[a-zA-Z0-9!@#$%^&*.]{8,30}$/;
    return re.test(password);
  },

  validateSearchingPhone (trimPhone) {
    const re = /^[+,0-9,(,)]*$/;
    return re.test(trimPhone);
  },

  isInteger: (input) => Number.isInteger(parseFloat(input)),
  isIntegerCheck (value) {
    var er = /^-?[0-9]+$/;
    return er.test(value);
  },
  isGreaterThan: (input, number) => parseFloat(input) > number,
  isLessThan: (input, number) => parseFloat(input) < number,
  isGreaterOrEqual: (input, number) => {
    if (input == '' || input === undefined || input == null) {
      input = 0;
    }
    return parseFloat(input) >= parseFloat(number);
  },
  timeFormat24h: (text) => {
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return timeRegex.test(text)
  },
  isLessOrEqual: (input, number) => {
    if (input == '' || input === undefined || input == null) {
      input = 0;
    }
    return parseFloat(input) <= parseFloat(number);
  },
  isInRange: (input, min, max) => {
    if (input == '' || input === undefined || input == null) {
      input = 0;
    }
    return parseFloat(input) >= min && parseFloat(input) <= max;
  },
  isStringEmpty (input) {
    if (input === undefined) {
      return true;
    }
    if (input === null) return true;
    if (typeof input === 'string') {
      if (input.trim().length == 0) {
        return true;
      }
    }
    return false;
  },
  isNumber (value) {
    if (value === undefined) {
      return false;
    }
    if (value === null) {
      return false;
    }
    if (typeof value === 'string') {
      if (value.trim().length == 0) {
        return false;
      }
    }
    if (isNaN(value)) {
      return false;
    }
    return true;
  },
  isDigitOnly (value) {
    return /^\d+$/.test(value);
  },
  checkTimeOverlapInDate (startTime1, endTime1, startTime2, endTime2) {
    const format = 'HH:mm';
    const range1 = moment.range(
      moment(startTime1, format),
      moment(endTime1, format)
    );
    const range2 = moment.range(
      moment(startTime2, format),
      moment(endTime2, format)
    );
    return range1.overlaps(range2);
  },
  isDecimal (value) {
    return /^(\d*\.?\d+|\d+\.)$/.test(value);
  },
  minutesOfDay (date) {
    return date.minutes() + date.hours() * 60;
  },
  validCreditsDateFormat (expiredDate) {
    if (expiredDate && expiredDate.length < 7) {
      return false;
    }
    return true;
  },
  validCreditsFutureDate (expiredDate) {
    if (expiredDate && expiredDate.length > 0) {
      let mon = new Date().getMonth() + 1;
      if (mon < 10) {
        mon = `0${mon}`;
      }
      const currentDate = new Date(`${new Date().getFullYear()}-${mon}`);
      const expireDateSplt = expiredDate.split(' / ');
      if (expireDateSplt.length > 1) {
        const compareDate = new Date(
          `20${expireDateSplt[1]}-${expireDateSplt[0]}`
        );
        if (compareDate > currentDate) {
          return true;
        }
      }
    }

    return false;
  },
  isMaxlength (input, length) {
    return input && input.length >= length;
  },
  insidePolygon (point, polygon) {
    if (!polygon || !point) {
      return true;
    }
    if (_.isArray(point)) {
      return inside(point, polygon);
    } else if (_.isObject(point)) {
      return inside([point.lng, point.lat], polygon);
    }
    return !!polygon || false;
  },
  isWeekDaysEmpty (weekDays = {}) {
    let isEmpty = true;

    for (let day in weekDays) {
      if (weekDays[day]) isEmpty = false;
    }

    return isEmpty;
  },
};

export const handleMultiSelectCompanyAll = (newArrOption, selectingOption, isMulti) => {
  let supplierValueArr = [];
  if (isMulti) {
    if (
      selectingOption.action === 'select-option' &&
      selectingOption.option.value === 'all'
    ) {
      supplierValueArr = [];
    } else {
      supplierValueArr = _.filter(
        newArrOption?.map((cp) => cp.value),
        (cp) => cp !== 'all'
      );
    }
  } else {
    if (newArrOption.value === 'all') {
      supplierValueArr = [];
    } else {
      supplierValueArr = [newArrOption.value];
    }
  }
  return supplierValueArr;
}

// kiểm tra  tất cả điều kiện theo company setting
// return true => book luôn luôn show vì thỏa broadcast
// return false => book không thỏa điều kiện broadcast
export const checkBroadcastWithSupplierSelecting = (
  fleetEnableBroadcastBooking,
  companyId,
  companyInfo,
  supplierCompanies,
  book,
  process
) => {
  if (!fleetEnableBroadcastBooking) return false;
  const companyIdOfDriver = book?.drvInfo?.companyId; // only has when book assigned driver
  if (
    (supplierCompanies != null && supplierCompanies.length > 0) ||
    companyIdOfDriver === companyId
  ) {
    if (supplierCompanies.includes(companyId)) {
      return true;
    }
  }
  const pickupTime = book?.request?.pickUpTime || 'Now';
  if (pickupTime === 'Now') {
    return false;
  }

  const {
    delayBroadcast = {},
    broadcastBooking,
    advanceBookingVisibility,
    releaseTime,
  } = companyInfo;

  const currentTime = moment(),
    pickupTimeMoment = moment(pickupTime),
    createdTimeMoment = moment(book.createdDate);

  const pickupDayFromStartTimeMoment = moment(
    book.time.pickupDayFromStart
  );

  const delayBroadcastInMinutes = delayBroadcast.valueInMinute || 0;
  const releaseTimeInMinutes = releaseTime || 0;
  const pickTimeCondition2 = currentTime
    .clone()
    .add(advanceBookingVisibility - 1, 'days')
    .subtract(delayBroadcastInMinutes + releaseTimeInMinutes, 'minute')
    .toDate();
  const shortNoticeInHours =
    process.shortNoticeOrderUnit === 'hour'
      ? process.shortNoticeOrder
      : process.shortNoticeOrder * 24;
  if (
    broadcastBooking &&
    (pickupTimeMoment.diff(currentTime, 'hours') <=
      process?.autoDispatchBefore ||
      pickupTimeMoment.diff(createdTimeMoment, 'minutes') <=
        shortNoticeInHours * 60  ||
      pickupDayFromStartTimeMoment.isBefore(pickTimeCondition2))
  ) {
    return true;
  }
  return false;
};

export const checkCompanyHasSupportPayment = (paymentInfo, company, paymentSelecting, broadcastBooking) => {
  if(broadcastBooking) return true
  if (
    !company ||
    !company?.paymentMethodsApply ||
    company?.paymentMethodsApply?.length === 0
  ) return true;
  const paymentList = [
    ...(paymentInfo.individualTypes || []),
    ...(paymentInfo.corporateTypes || []),
  ];
  const paymentSelectingInfo =
    paymentList.find((pm) => pm.value === paymentSelecting) || {};
  return company.paymentMethodsApply?.includes(paymentSelectingInfo.key);
};

export const getSupplierInfoFromID = (companyList = [], id) => {
  if (!companyList || companyList.length === 0 || !id) return {};
  const companyInfo = companyList.find((cp) => cp._id === id);
  return companyInfo?.type === 'supplier' ? companyInfo : {};
};

export const convertDataWhenChangeSupplier = (
  newArrOption,
  selectingOption,
  isMulti,
  auth,
  companySelecting,
  commonData
) => {
  if (
    !checkCanRemoveLastOptionCorporate(
      newArrOption,
      selectingOption,
      auth?.user,
      companySelecting
    )
  )
    return;

  const supplierIds = handleMultiSelectCompanyAll(
      newArrOption,
      selectingOption,
      isMulti
    ),
    supplierInfoFirst = getSupplierInfoFromID(
      [...commonData.companies, ...commonData.suppliers],
      supplierIds[0]
    );
  return {
    supplierIds,
    supplierInfoFirst,
  };
};

export const filterSupplierByZone = (supplier, bookings) => {
  if (!bookings || bookings.length === 0 || !supplier || supplier.length === 0)
    return [];
  let supplierFinal = [],
    everyBkAllComapny = true;
  const zoneUniqBookings = _.uniq(
    bookings.map((bk) => bk.request?.pickup?.zoneId)
  );

  // kiểm tra tất cả books đều all company
  _.each(supplier, (s) => {
    if (
      !s.operationZone || // old company
      s.operationZone.length === 0 || // old company
      // _.isEmpty(_.difference(zoneUniqBookings, s.operationZone)) // supplier must support zone of all bookings
      zoneUniqBookings.some(z => s.operationZone.includes(z))
    ) {
      supplierFinal.push(s);
    } else {
      everyBkAllComapny = false;
    }
  });
  // if (!everyBkAllComapny) _.remove(supplierFinal, (s) => s.value === 'all'); // remove all options
  return supplierFinal;
};

export const showWarningAssignSupplier = (
  bookings,
  supplierSelected,
  companies
) => {
  if (
    !supplierSelected ||
    supplierSelected?.some((s) => s.value === 'all') ||
    !companies ||
    !bookings
  )
    return false;
  const suppliersInfo = companies?.filter((c) =>
    supplierSelected?.some((s) => s.value === c._id)
  );
  const zoneUniqBookings = _.uniq(
    bookings.map((bk) => bk.request?.pickup?.zoneId)
  );
  // kiểm tra list supplier đều support all zone of books
  return !_.every(suppliersInfo, (s) => {
    if (
      !s.operationZone || // old company
      s.operationZone.length === 0 || // old company
      _.isEmpty(_.difference(zoneUniqBookings, s.operationZone)) // supplier must support zone of all bookings
    ) {
      return true;
    }
    return false;
  });
};

export const filterCompanyList = (
  traveler_type,
  corporateInfo = {},
  allCompany,
  userTypeCurrent,
  zonePickup
) => {
  // #SL-27667
  // Nếu Corp có custome list company:
  //  - Fleet user, Fleet admin được thấy hết
  //  - Corp user, Corp admin thì show/hide theo setting
  let companyFinal = allCompany || [];
  if (
    traveler_type == 1 &&
    ![userType.FleetAdmin, userType.FleetUser].includes(userTypeCurrent)
  ) {
    let supplier = corporateInfo.supplier || {};
    if (supplier.type === SUPPLIER_TYPE.customized) {
      const companyCorporateSetting = supplier.value || [];
      companyFinal = [];
      allCompany.map((company) => {
        if (companyCorporateSetting.indexOf(company._id) > -1) {
          companyFinal.push(company);
        }
      });
    }
  }

  // filter theo zone pickup
  if (zonePickup?._id) {
    companyFinal = companyFinal.filter((cp) => {
      return (
        !cp.operationZone ||
        cp.operationZone?.length === 0 ||
        cp.operationZone?.includes(zonePickup?._id)
      );
    });
  }
  return companyFinal;
};

export const textboxNumberHelper = {
  onfocusHandle: (e, onChangehandle) => {
    if (
      e.target.value == 0 ||
      e.target.value.trim() == '0' ||
      e.target.value == 'N/A' ||
      e.target.value.trim() == 'N/A'
    ) {
      e.target.value = '';
      if (onChangehandle) {
        onChangehandle(e);
      }
    }
  },
  onBlurHandle: (e, onChangehandle) => {
    if (!e.target.value) {
      e.target.value = '0';
      if (onChangehandle) {
        onChangehandle(e);
      }
    }
  },
};

export const makeRandomStringAndNumber = (length) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const filterTransportCarTypes = (cars = []) => {
  if(!cars || cars.length === 0) return []
  return cars.filter(
    (ob) =>
      !ob.shuttle &&
      !ob.delivery &&
      !ob.superHelper &&
      !ob.food &&
      !ob.mart &&
      !ob.dispatchRideSharing &&
      !ob.intercity
  );
}

export const filterDeliveryCarTypes = (cars = []) => {
  if(!cars || cars.length === 0) return []
  return cars.filter(
    (ob) => ob.delivery
  );
}

export const filterCommissionServiceActive = (
  commissions = [],
  fleetconfig = {},
  isCompany = false
) => {
  if (_.isEmpty(fleetconfig)) return [];
  let serviceActive = [];
  if (fleetconfig.transport && fleetconfig.transport.enable)
    serviceActive.push('transport');
  if (fleetconfig.hasCarHailingActive && fleetconfig.hasCarHailingActive)
    serviceActive.push('rideHailing');
  if (fleetconfig.hasCarStreetSharingActive)
    serviceActive.push('streetSharing');
  if (fleetconfig.intercity && fleetconfig.intercity.enable)
    serviceActive.push('intercity');
  if (fleetconfig.shuttle && fleetconfig.shuttle.enable)
    serviceActive.push('shuttle');
  if (fleetconfig.delivery && fleetconfig.delivery.enable)
    serviceActive.push('parcel');
  if (fleetconfig.food && fleetconfig.food.enable) serviceActive.push('food');
  if (fleetconfig.mart && fleetconfig.mart.enable) serviceActive.push('mart');
  serviceActive.map((s) => {
    var index = commissions && commissions.findIndex((c) => c.serviceType == s);
    if (index === -1) {
      if(isCompany) {
        commissions.push({
          serviceType: s,
          value: 0,
        });
      } else {
        commissions.push({
          serviceType: s,
          type: 'percent',
          value: 'N/A',
          insurance: 0,
        });
      }
    }
  });
  return _.sortBy(
    commissions?.filter(
      (commission) => serviceActive?.indexOf(commission.serviceType) > -1
    ),
    function (item) {
      return serviceActive?.indexOf(item.serviceType);
    }
  );
};

export const dispatchOuterTableChange = () => {
  window.dispatchEvent(outerTableChangeEvent);
};

export const getUrlLoadAddCardTSYS = (secureID, sessionId, showAddress) => {
  return `${process.env.REACT_APP_IFRAME_TSYS}/?secureID=${secureID}&sessionId=${sessionId}&showAddress=${showAddress}`;
};

export const getTableDynamicColumn = (tableName, returnObject = false) => {
  const localObject = localStorage.getItem('dynamic_columns');
  if (!localObject) {
    if (returnObject) return {};
    return [];
  }
  let dynamicColumnsObj = {};
  try {
    dynamicColumnsObj = JSON.parse(localObject);
  } catch (error) {
    dynamicColumnsObj = {};
  }
  if (returnObject) return dynamicColumnsObj;
  if (!dynamicColumnsObj[tableName] || !_.isArray(dynamicColumnsObj[tableName]))
    return [];
  return dynamicColumnsObj[tableName];
};

export const setTableDynamicColumn = (tableName, list) => {
  if (!list || !_.isArray(list)) {
    // console.error('list must be array');
    return false;
  }
  if (!tableName) {
    // console.error('tableName is required');
    return false;
  }
  const localObject = getTableDynamicColumn('', true);
  localObject[tableName] = list;
  localStorage.setItem('dynamic_columns', JSON.stringify(localObject));
};

export const getTimeZoneLocal = (search, page, limit = 20) => {
  const searchLower = search.trim().toLowerCase();
  const query = _.filter(
    TimeZones,
    (item) => item.text.toLowerCase().indexOf(searchLower) !== -1
  );
  const total = query.length;
  const list = query.splice(page * limit, limit);
  return {
    total,
    list,
    limit,
    page,
  };
};

export const isMobile = () => {
  const width = window.innerWidth;
  return width < 769;
};

export const toLocaleNumber = (number, locale) => {
  const parsed = parseFloat(number);
  if (isNaN(parsed)) return number;
  return parsed.toLocaleString('us');
  // let value = ""
  // let code = locale || localStorage.getItem("fleet_country_code")
  // try {
  //   value = parsed.toLocaleString(code)
  // } catch(e) {
  //   value = parsed.toLocaleString()
  // }
  // return value
};

export function convertToHourly (chargeOption) {
  const unit = chargeOption.unit || 'hours';
  const value = chargeOption.value || 0;
  switch (unit) {
    case 'days':
      return value * 24;

    case 'hours':
      return value;
  }
}

export function formatDateYYYYMMdd (date) {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join('-');
}

export function isSameLocation (location1, location2) {
  if (
    location1 &&
    location1.lat &&
    location1.lng &&
    location2 &&
    location2.lat &&
    location2.lng
  ) {
    return location1.lat === location2.lat && location1.lng === location2.lng;
  }
  return false;
}

export const convertTextStatus = (stripeConnectStatus) => {
  let statusTxt = 'Inactive'
  switch(stripeConnectStatus) {
    case 'pending':
      statusTxt = 'In review'
      break;
      case 'activated':
      statusTxt = 'Active'
      break;
    default:
  }
  return statusTxt
}

export function checkDropOffCountry (
  dropOffCountry,
  locationPickUp,
  locationDestination
) {
  if (!dropOffCountry) {
    return { valid: true };
  }
  const limitCountry = dropOffCountry.countries || [];
  const countryCodeDestination = locationDestination
    ? locationDestination.countryCode
    : '';
  const countryCodepickup = locationPickUp ? locationPickUp.countryCode : '';
  if (!_.isEmpty(limitCountry) && countryCodeDestination) {
    let countryCodeDo = countryCodeDestination
    // cho trường hợp dùng tencent map countryCode sẽ khác
    switch (countryCodeDestination) {
      case 'China':
        countryCodeDo = 'CN'
        break;
      case 'Hong Kong':
        countryCodeDo = 'HK'
        break;
      default:
        break;
    }
    if (limitCountry.indexOf(countryCodeDo) == -1) {
      return { valid: false, err: 0 };
    }
    if (
      dropOffCountry.differentPickup &&
      countryCodepickup == countryCodeDestination
    ) {
      return { valid: false, err: 1 };
    }
  }
  return { valid: true };
}

export function existLatLng (localtion) {
  if (localtion && localtion.lat == 0 && localtion.lng == 0) return false;
  return true;
}

export const isChinaLocation = (commonData) =>
  _.get(commonData, 'location.isChina', false);

export const convertToMinutesFromType = (value, type) => { // exp: 02:30 => 150
  if(!value) return 0
  if(type == UNIT_TIME.Hour) return value * 60
  if(type == UNIT_TIME.Day) return value * 60 * 24
  return value
}

export const convertToMinutes = (time) => { // exp: 02:30 => 150
  const duration = moment.duration(time);
  return duration.asMinutes();
}

export const convertToHours = (minutes) => { // exp: 150 => 02:30
  const duration = moment.duration(minutes, 'minutes');
  return moment.utc(duration.asMilliseconds()).format("HH:mm");
}

export const roundOff = (amount, currencyISO, setting) => {
  if (!setting || !setting.enable) {
    return amount;
  }
  const roundOffSettings = _.find(
    setting.roundingByCurrencies,
    (o) => o.currencyISO === currencyISO
  );
  if (!roundOffSettings) {
    return amount;
  }

  const { mode, digits = 0 } = roundOffSettings;
  if (mode === 'round') {
    return _.round(amount, digits);
  }
  if (mode === 'roundUp') {
    return _.ceil(amount, digits);
  }
  if (mode === 'roundDown') {
    return _.floor(amount, digits);
  }
  return amount;
};

export function getBooksBetween(bookLast, bookEnd, books) {
  let startIndex = books.findIndex((item) => item.bookId === bookLast.bookId);
  let endIndex = books.findIndex((item) => item.bookId === bookEnd.bookId);

  if (startIndex === -1 || endIndex === -1) {
    return [];
  }

  if (startIndex > endIndex) {
    [startIndex, endIndex] = [endIndex, startIndex];
  }

  return books.slice(startIndex, endIndex + 1);
}

export const checkEnable3rdPartyIntegration = (selectedFleet) => {
  const integrationSetting = _.get(selectedFleet, 'modules', [])
    .find(md => md.name === 'Third Party Integration Booking')
  return !_.isEmpty(integrationSetting)
}

export const checkEnableHolidayTaxis = (selectedFleet) => {
  const integrationSetting = _.get(selectedFleet, 'modules', [])
    .find(md => md.name === 'Third Party Integration HolidayTaxis')
  return !_.isEmpty(integrationSetting)
}

export const checkEnableKarhoo = (selectedFleet) => {
  return selectedFleet?.generalSetting?.allowReceiveKarhooBooking || false
}

export const renderMSGElement = (msg = '', thirdPartyName = '') => {
  const newMsg = msg.replace('{{thirdParty}}', `<span class="activeText">${thirdPartyName}</span>`)
  return parse(`<p class="noti-item-title">${newMsg}</p>`)
}

export const convertTravelerTypeToText = (
  traveler_type = 0,
  isAirline = false
) => {
  if (traveler_type === 'corporation' && isAirline) {
    return 'airline';
  }
  return traveler_type;
};

export const getTravelerTypeText = (traveler_type, company) => {
  const travelerType = traveler_type == 0 ? 'individual' : 'corporation';
  const isAirlineCorporate = company && company.isAirline || false
  return convertTravelerTypeToText(travelerType, isAirlineCorporate)
}

export const getCompanyIdForETA = (companyInfo, driverSelected) => {
  let companyId = _.get(companyInfo, '_id', null)
  if(!companyId && _.get(driverSelected, 'company.companyId')) {
    companyId = _.get(driverSelected, 'company.companyId')
  }
  return companyId
}

const ignoreCase = ['Booking', 'Report', 'Setting', 'Map'];

const compareModule = (m1, m2) => {
  if (m1.key == m2.key && !ignoreCase.includes(m1.key)) return 0;
  if (m1.key == 'Cue') return -1;
  if (m2.key == 'Cue') return 1;
  if (m1.key < m2.key && !ignoreCase.includes(m1.key)) {
    return -1;
  }
  if (m1.key > m2.key && ignoreCase.includes(m2.key)) {
    return 1;
  }
  return 0;
};

export const getPermissionViewByName = (user = {}, nameModule, nameActions) => {
  if(user && user.userType === 'FleetAdmin') return true
  const rolse = _.get(user, 'roles.modules', [])
  let moduleView = {}
  if (rolse.length > 0) {
    let nameRole = nameModule
    let newNameActions = nameActions ? nameActions : 'View'
    if(nameModule === 'Booking logs') {
      nameRole = 'Report'
      newNameActions = 'Booking logs'
    }
    let permissionCorporate = rolse.find((ob) => ob.name === nameRole)
      if( permissionCorporate 
          && permissionCorporate.actions) moduleView = permissionCorporate.actions.find((ob) => ob.name === newNameActions)
      if(moduleView && moduleView.isActive) {
          return true
      }
  }
  return false
}

export const getHasPermissionModule = (permissions, modules) => {
  const { view = false } = permissions || {};
  if (!view) {
    modules.sort(compareModule);
    modules = _.filter(modules, function (o) {
      return !ignoreCase.includes(o.key);
    });
    for (let j = 0; j < (modules || []).length; j++) {
      const module = modules[j];
      const { actions = [], key, name } = module;
      // if (ignoreCase.includes(key)) return;
      for (let i = 0; i < (actions || []).length; i++) {
        const action = actions[i];
        if (action.isActive) {
          if (key === 'Dashboard') {
            const url = `/analytics`;
            return {
              url,
              key: 'Analytics',
            };
          } else if (key === 'QueuingArea') {
            const url = `/Queuingarea`;
            return {
              url,
              key: 'Queuingarea',
            };
          } else if (key === 'Reservation') {
            const url = `/calendar`;
            return {
              url,
              key: 'Calendar',
            };
          } else if (key === 'SmartData') {
            const url = `/smartdata`;
            return {
              url,
              key: 'SmartData',
            };
          } else if (key === 'Settlement') {
            const url = `/settlement/pay_to_driver`;
            return {
              url,
              key: 'Settlement',
            };
          } else if (key === 'WithdrawalRequests') {
            const url = `/withdrawal_requests`;
            return {
              url,
              key: 'Withdrawal_Requests',
            };
          }
          //  else if (key === 'DriverPayout') {
          //   const url = `/DriverPayout/pay_to_driver`;
          //   return {
          //     url,
          //     key: 'DriverPayout'
          //   };
          // }
          else if (key === 'Payout') {
            const partUrl = name.replace(/\s/g, '_');
            const url = `/Payout/${partUrl}`;
            return {
              url,
              key: 'Payout',
            };
          } else if (key === 'Report') {
            const partUrl = action.name.replace(/\s/g, '_');
            const url = `/reports/${partUrl}`;
            return {
              url,
              key: 'Reports',
            };
          } else if (action.name === 'View') {
            const orgUrl = key === 'Setting' ? '/settings' : '';
            const keyMenu = key === 'Setting' ? 'Settings' : key;
            const partUrl = name.replace(/\s/g, '_');
            const url = `${orgUrl}/${partUrl}`;
            return {
              url,
              key: keyMenu,
            };
          }
        }
      }
    }
    const url = '/profile';
    return {
      url,
      key: 'profile',
    };
  }
  return null;
};
export const formatNumber = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return formatter.format(amount);
};
export const ObjectId = (rnd = (r16) => Math.floor(r16).toString(16)) =>
  rnd(Date.now() / 1000) +
  ' '.repeat(16).replace(/./g, () => rnd(Math.random() * 16));

export const convertFormat24h = (format24h) => {
  return format24h ? "HH:mm" : "hh:mm A"
}

export const removeDuplicateArray = (arrs, key) => {
  if (!arrs || !arrs.length) return [];

  if (!key || !key.length) {
    const res = arrs.reduce((newArrs, item) => {
      if (!newArrs || !newArrs.length || !newArrs.includes(item))
        newArrs.push(item);
      return newArrs;
    }, []);

    return res;
  } else {
    const res = arrs.reduce((newArrs, item) => {
      if (!newArrs || !newArrs.length) return [item];
      const existedKey = newArrs.find((e) => e[key] === item[key]);

      if (!existedKey) newArrs.push(item);
      return newArrs;
    }, []);
    return res;
  }

  return arrs;
};

export const parStart = (value, length = 2) => {
  if (_.isNil(value)) return value;
  return value.toString().padStart(length, '0');
};

export const distanceConvert = (value, unit, isSave = false) => {
  if (isSave) {
    // Convert (from Km or Mile) to Meter
    if (unit === 'km') {
      return value * 1000;
    } else {
      return value * 1609.344;
    }
  } else {
    if (unit === 'km') {
      return value / 1000;
    } else {
      return value / 1609.344;
    }
  }
};

export const pointsConvertByUnitDistance = (value, unit, isSave = false) => {
  if (isSave) {
    // Convert (from Km or Mile) to Meter
    if (unit === 'km') {
      return value / 1000;
    } else {
      return value / 1609.344;
    }
  } else {
    if (unit === 'km') {
      return value * 1000;
    } else {
      return value * 1609.344;
    }
  }
};

export const checkEnableStripe = (selectedFleet = {}) => {
  const fleetGateway = selectedFleet?.creditConfig?.configGateway?.gateway
  return fleetGateway === 'Stripe' && selectedFleet?.stripePublicKey
}

export const filterPaymentMethods = (listPaymentMethods, fleetData) => {
  let paymentMethods = listPaymentMethods || {};
  if (!_.get(fleetData, 'delivery.enable', false)) {
    paymentMethods = _.filter(
      paymentMethods,
      (o) => o.value !== 'cashBySender' && o.value !== 'cashByRecipient'
    );
  }
  if (!_.get(fleetData, 'tnGeWalletConfig.isActive', false)) {
    paymentMethods = _.filter(paymentMethods, (o) => o.value !== 'tngeWallet');
  }
  if (!_.get(fleetData, 'vippsWalletConfig.isActive', false)) {
    paymentMethods = _.filter(
      paymentMethods,
      (o) => o.value !== 'vippseWallet'
    );
  }
  if (!_.get(fleetData, 'zainCashWalletConfig.isActive', false)) {
    paymentMethods = _.filter(
      paymentMethods,
      (item) => item.value !== 'ZainCash'
    );
  }
  if (!_.get(fleetData, 'telebirrWalletConfig.isActive', false)) {
    paymentMethods = _.filter(
      paymentMethods,
      (item) => item.value !== 'Telebirr'
    );
  }
  if (!_.get(fleetData, 'ksherWalletConfig.isActive', false)) {
    paymentMethods = _.filter(paymentMethods, (item) => item.value !== 'Ksher');
  }
  if (!_.get(fleetData, 'gCashWalletConfig.isActive', false)) {
    paymentMethods = _.filter(paymentMethods, (item) => item.value !== 'GCash');
  }
  if (!_.get(fleetData, 'generalSetting.allowStripePaymentLink', false)) {
    paymentMethods = _.filter(paymentMethods, (item) => item.value !== 'paymentLink');
  }
  if(!_.get(fleetData, 'googlePayConfig.isActive', false)) {
    paymentMethods = _.filter(paymentMethods, (item) => item.value !== 'googlePay');
  }
  if(!_.get(fleetData, 'applePayConfig.isActive', false)) {
    paymentMethods = _.filter(paymentMethods, (item) => item.value !== 'applePay');
  }
  if(!_.get(fleetData, 'easyPaisaConfig.isActive', false)) {
    paymentMethods = _.filter(paymentMethods, (item) => item.value !== 'easyPaisa');
  }
  if(!_.get(fleetData, 'jazzCashConfig.isActive', false)) {
    paymentMethods = _.filter(paymentMethods, (item) => item.value !== 'jazzCash');
  }

  // Nếu gateway là xendit thì chỉ show payment chanel
  paymentMethods = _.filter(paymentMethods, (item) => item.value !== 'Xendit');
  if (_.get(fleetData, 'xenditWalletConfig.isActive', false)) {
    paymentMethods = [
      ...paymentMethods,
      ...(getChannelPaymentXendit({fleetData, listPaymentMethods}) || [])
    ]
  }
  
  gateway_port_21.map((gateway) => {
    if (!_.get(fleetData, `${gateway}WalletConfig.isActive`, false)) {
      paymentMethods = _.filter(
        paymentMethods,
        (item) => item.value !== gateway
      );
    }
  });
  return paymentMethods;
};

export const getChannelPaymentXendit = ({fleetData, listPaymentMethods}) => {
  let channelPm = [];

  const paymentChannelXendit = (fleetData?.paymentChannel?.list || []).filter(
    (pm) =>
      pm.type === 'PaxWallet' &&
      pm.gateway === 'Xendit' &&
      pm.country === 'Philippines' &&
      pm.paymentType === 'tokenized'
  );
  paymentChannelXendit?.map((c) => {
    channelPm.push({
      value: c.name,
      label: `report.query.paymentMethodItem.${c.name}`,
    });
  });

  // Nếu có ít nhất 1 paymentType === 'oneTime' thì add thêm Xendit payment
  if (
    (fleetData?.paymentChannel?.list || []).some(
      (pm) =>
        pm.type === 'PaxWallet' &&
        pm.gateway === 'Xendit' &&
        pm.country === 'Philippines' &&
        pm.paymentType === 'oneTime'
    )
  ) {
    const xenditMethod = listPaymentMethods.find((pm) => pm.value === 'Xendit');
    if (xenditMethod) {
      channelPm.push(xenditMethod);
    }
  }
  return channelPm;
};

export const checkLastActiveRecipient = (recipients = []) => {
  if(!recipients || recipients.length === 0) return false
  let numberActiveRecipient = 0
  recipients.map(rcp => {
    if(rcp.status && !STATUS_COMPLETED_DELIVERY.includes(rcp.status)) {
      numberActiveRecipient++
    }
  })
  if(numberActiveRecipient === 1) return true
  return false
}

export const convertDateRangeString = (key) => {
  let newState = {};
  const localeCode = 'vi';
  switch (key) {
    case 'today':
      return (newState = {
        fromDate: moment().startOf('day').toISOString(),
        toDate: moment().endOf('day').toISOString(),
      });
    case 'tomorrow':
      return (newState = {
        fromDate: moment().subtract(-1, 'days').startOf('day').toISOString(),
        toDate: moment().endOf('day').toISOString(),
      });
    case 'yesterday':
      return (newState = {
        fromDate: moment().subtract(1, 'days').startOf('day').toISOString(),
        toDate: moment().subtract(1, 'days').endOf('day').toISOString(),
      });
    case 'thisMonth':
      return (newState = {
        fromDate: moment().startOf('month').toISOString(),
        toDate: moment().endOf('month').toISOString(),
      });
    case 'lastMonth':
      return (newState = {
        fromDate: moment().subtract(1, 'month').startOf('month').toISOString(),
        toDate: moment().subtract(1, 'month').endOf('month').toISOString(),
      });
    case 'nextMonth':
      return (newState = {
        fromDate: moment().subtract(-1, 'month').startOf('month').toISOString(),
        toDate: moment().subtract(-1, 'month').endOf('month').toISOString(),
      });
    case 'past30Days':
      return (newState = {
        fromDate: moment().subtract(29, 'days').startOf('day').toISOString(),
        toDate: moment().endOf('day').toISOString(),
      });
    case 'past7Days':
      return (newState = {
        fromDate: moment().subtract(6, 'days').startOf('day').toISOString(),
        toDate: moment().endOf('day').toISOString(),
      });
    case 'thisYear':
      return (newState = {
        fromDate: moment().startOf('year').toISOString(),
        toDate: moment().endOf('year').toISOString(),
      });
    case 'thisWeek':
      return (newState = {
        fromDate: moment()
          .locale(localeCode)
          .startOf('week')
          .startOf('day')
          .toISOString(),
        toDate: moment()
          .locale(localeCode)
          .endOf('week')
          .endOf('day')
          .toISOString(),
      });
    case 'nextWeek':
      return (newState = {
        fromDate: moment()
          .subtract(-1, 'week')
          .locale(localeCode)
          .startOf('week')
          .startOf('day')
          .toISOString(),
        toDate: moment()
          .subtract(-1, 'week')
          .locale(localeCode)
          .endOf('week')
          .endOf('day')
          .toISOString(),
      });
    case 'lastWeek':
      return (newState = {
        fromDate: moment()
          .subtract(1, 'week')
          .locale(localeCode)
          .startOf('week')
          .startOf('day')
          .toISOString(),
        toDate: moment()
          .subtract(1, 'week')
          .locale(localeCode)
          .endOf('week')
          .endOf('day')
          .toISOString(),
      });
    default:
      return newState;
  }
};

export const getFilterFavorite = (key) => {
  if (!key) return;
  const favoriteId = localStorage.getItem(key);
  return favoriteId;
};

export const getDataFromLocalStorage = (key) => {
  try {
      return JSON.parse(localStorage.getItem(key)) || {}
  } catch (error) {
      console.error("Invalid JSON format:", error);
      return {}
  }
}

export const setFilterFavorite = (key, id) => {
  if (!key) return;
  localStorage.setItem(key, id);
};
export const getLineShort = () => {
  const localData =
    localStorage.getItem('line_short') === 'true' ? true : false;
  return localData;
};

export const setLineShort = (data) => {
  localStorage.setItem('line_short', data);
};

export const regexpDriverAutoComplete = (listData = [], escapedValue) => {
  const regex = new RegExp(`\\b${escapedValue}`, 'i');
  const nonWordBoundary = '[^\\p{L}]';

  const result = listData.filter((item) => {
    return (
      regex.test(item.driver.name) ||
      regex.test(item.vehicle.plateNumber) ||
      regex.test(item.phone) ||
      regex.test(item.firstName) ||
      regex.test(item.lastName) ||
      new RegExp(`(^|${nonWordBoundary})${escapedValue}`, 'gui').test(item.driver.name) ||
      new RegExp(`(^|${nonWordBoundary})${escapedValue}`, 'gui').test(item.vehicle.plateNumber) ||
      new RegExp(`(^|${nonWordBoundary})${escapedValue}`, 'gui').test(item.phone) ||
      new RegExp(`(^|${nonWordBoundary})${escapedValue}`, 'gui').test(item.firstName) ||
      new RegExp(`(^|${nonWordBoundary})${escapedValue}`, 'gui').test(item.lastName)
    );
  });
  return result;
};

export const getCreditTransactionFeeSetting = (
  transactionFee = {},
  paymentMethod = 0
) => {
  let creditTransactionFeePercent = 0;
  let creditTransactionFeeAmount = 0;
  if (
    paymentMethod == 2 ||
    paymentMethod == 3 ||
    paymentMethod == 4 ||
    paymentMethod == 7 ||
    paymentMethod == 9 ||
    paymentMethod == 23
  ) {
    creditTransactionFeePercent = transactionFee.creditCardPercent;
    creditTransactionFeeAmount = transactionFee.creditCardAmount;
  } else if (paymentMethod == 5) {
    creditTransactionFeePercent = transactionFee.directBillingPercent;
    creditTransactionFeeAmount = transactionFee.directBillingAmount;
  } else if (paymentMethod == 6 || paymentMethod == 12) {
    creditTransactionFeePercent = transactionFee.externalCardPercent;
    creditTransactionFeeAmount = transactionFee.externalCardAmount;
  }
  return {
    creditTransactionFeePercent: creditTransactionFeePercent,
    creditTransactionFeeAmount: creditTransactionFeeAmount,
  };
};

export function getServiceType (booking = {}) {
  if (booking.intercity === true) {
    return BOOK_TYPE.intercity;
  }
  if (booking.shuttle === true) {
    return BOOK_TYPE.shuttle;
  }
  if (booking.superHelper === true || booking.delivery === true) {
    if(booking.request && booking.request.rideSharing) {
      return BOOK_TYPE.batchDelivery
    }
    return BOOK_TYPE.delivery;
  }
  if (booking.bookFrom === 'Car-hailing') {
    return BOOK_TYPE.carHailing;
  }
  return booking.jobType;
}

export function getBookingType (booking = {}) {
  let jobType = getServiceType(booking);
  const pickupTime = _.get(booking, 'request.pickUpTime', 'Now');
  const isRideSharing = _.get(booking, 'request.rideSharing', false);
  if (jobType === 'rideHailing') {
    if (isRideSharing) return BOOK_TYPE.rideSharing;
    if (pickupTime === 'Now') return BOOK_TYPE.now;
    if (pickupTime != 'Now') return BOOK_TYPE.reservation;
  }
  return jobType;
}

export function getBookingTypeGroup (booking = {}) {
  if(booking.groupId) return TYPE_BOOKING_GROUP.manifest
  let bookType = getBookingType(booking);
  /**
    nếu book now thì chỉ được chọn 1 book để  assign driver
    + không được manifest, assign supplier, detach và assign driver
  */
  if(bookType === BOOK_TYPE.now) return TYPE_BOOKING_GROUP.transport_now
  
  if([BOOK_TYPE.now, BOOK_TYPE.reservation, BOOK_TYPE.rideSharing].includes(bookType)) return TYPE_BOOKING_GROUP.tranpsort
  if([BOOK_TYPE.delivery, BOOK_TYPE.batchDelivery].includes(bookType)) return TYPE_BOOKING_GROUP.delivery
  if([BOOK_TYPE.shuttle].includes(bookType)) return TYPE_BOOKING_GROUP.shuttle
  if([BOOK_TYPE.intercity].includes(bookType)) return TYPE_BOOKING_GROUP.intercity
  return ''
}

export function handleResultExport (resp, isCheckExportExisted) {
  if (!resp.error && resp.res && !resp.res.errorCode) {
    if (isCheckExportExisted) {
      this.setState({
        showModalGetEmail: true,
      });
      return;
    }
    this.setState({
      showModalGetEmail: false,
    });
    this.context.notification(
      'success',
      I18n.t('report.query.messageSuccess'),
      '',
      () => {
        window.location = '/reports/export?isRedirect=true';
      },
      6000
    )
  } else {
    if (resp.res && resp.res.errorCode === 400006) {
      this.setState({
        showModalGetEmail: false,
        confirm: {
          hasConfirm: true,
          show: true,
          title: 'Export to Excel',
          body: '<p>An<a href="/reports/export?isRedirect=true"> export</a> is currently in progress. Kindly wait until it completes before initiating another export. Thank you!</p>',
          buttonTitle: 'View Export History',
          handleConfirmButtonClick: () => {
            window.location = '/reports/export?isRedirect=true';
          }
        },
      });
      return;
    }
    if (resp.res && resp.res.errorCode === 400007 && resp.res.filePath) {
      window.location = resp.res.filePath;
      this.setState({
        showModalGetEmail: false,
      });
      return;
    }
    return this.context.notification(
      'error',
      'The errors occurred while exporting'
    );
  }
}

export const getURLFareDeeplink = (fare = {}) => {
  const { rateType, rateId } = fare
  let deeplink = ''
  if(rateType && rateId) {
    switch (rateType) {
      case 'regular':
        deeplink = `/settings/rate/regular?rateId=${rateId}`
        break;
      case 'hourly':
        deeplink = `/settings/rate/hourly?rateId=${rateId}`
        break;
      case 'flat':
        deeplink = `/settings/rate/flat?rateId=${rateId}`
        break;
      case 'intercity':
        deeplink = `/settings/rate/intercity?rateId=${rateId}`
        break;
      case 'delivery':
        deeplink = `/settings/rate/delivery?rateId=${rateId}`
        break;
      default:
        break;
    }
  }
  return deeplink
}

export const clearParams = (params) => {
  return _.omitBy(
    params,
    (value) =>
      _.isNil(value) || (_.isObject(value) && _.isEmpty(value)) || value === ''
  );
};

export const getLanguageAutoCompleteSearch = (language) => {
  if(!language || language === 'en-US') return null
  return language
}

export const getURLToCheck3rdBooking = (dataBooking) => {
  const nameThirdParty = dataBooking?.bookFrom;
  const externalId =
    dataBooking?.externalId || dataBooking?.externalInfo?.bookingReference;

  if (nameThirdParty === thirdPartyIntegration.bookingAPI && externalId) {
    return `${process.env.REACT_APP_BOOKINGDOTCOM_URL}/reports/rides/${externalId}`;
  }

  if (nameThirdParty === thirdPartyIntegration.holidaytaxis && externalId) {
    return `${process.env.REACT_APP_HOLIDAYTAXIS_URL}/bookings/index.php?pagemode=search&bookingreference=${externalId}&isresults=1&pageaction=search`;
  }

  return '';
};

export const checkEnableTechFee = ({ selectedFleet, fleetFareCustom }) => {
  if(selectedFleet?.fleetFareId?.applyType === APPLY_SERVICE_TYPE.CUSTOM_ZONE) {
    return fleetFareCustom?.techFeeActive
  } else {
    return selectedFleet?.techFeeActive
  }
}

export const getFareFields = (
  selectedFleet,
  fareSettings,
  etaFare,
  bookType,
  data = {},
  is3rdBooking
) => {

  // 3rd booking 
  if(is3rdBooking && data?.externalInfo?.thirdParty === thirdPartyIntegration.holidaytaxis) {
    return {
      mainField: ModalEditFareField.thirdPartyField[data?.externalInfo?.thirdParty],
      subField: []
    }
  }

  let removeField = [];
  const fleetFareType = _.get(fareSettings, 'fare.applyType', APPLY_SERVICE_TYPE.ALL)
  if(fleetFareType === APPLY_SERVICE_TYPE.ALL) {
    if (!selectedFleet.techFeeActive) removeField.push('techFee');
    if (!selectedFleet.fleetFareId.tollFeeActive) removeField.push('tollFee');
    if (!selectedFleet.fleetFareId.parkingFeeActive) removeField.push('parkingFee');
    if (!selectedFleet.fleetFareId.gasFeeActive) removeField.push('gasFee');
    if (!selectedFleet.fleetFareId.taxActive) removeField.push('tax');
    if (!selectedFleet.fleetFareId.otherFeeActive) removeField.push('otherFees');
    if (!selectedFleet.fleetFareId.rushHourActive) removeField.push('rushHourFee');
  } else {
    const fareSettingByZone = _.get(fareSettings, 'fare', {})
    if (!fareSettingByZone.otherFeeActive) removeField.push('otherFees');
    if (!fareSettingByZone.rushHourActive) removeField.push('rushHourFee');
    if (!fareSettingByZone.techFeeActive) removeField.push('techFee');
    if (!fareSettingByZone.tollFeeActive) removeField.push('tollFee');
    if (!fareSettingByZone.parkingFeeActive) removeField.push('parkingFee');
    if (!fareSettingByZone.gasFeeActive) removeField.push('gasFee');
    if (!fareSettingByZone.taxActive) removeField.push('tax');
  }

  if (etaFare.bookingFee <= 0) removeField.push('bookingFee');
  if (etaFare.creditTransactionFee <= 0)
    removeField.push('creditTransactionFee');
  if (!selectedFleet.fleetFareId.tipActive) removeField.push('tip');
  if (
    !selectedFleet.fleetFareId.additionalServicesActive ||
    data.isNewSettingAdditionFee
  )
    removeField.push('serviceFee');
  if (!data.isNewSettingAdditionFee) removeField.push('fleetService');
  if (
    !fareSettings?.fare?.airport?.fromAirportActive &&
    !fareSettings?.fare?.airport?.toAirportActive
  ) {
    removeField.push('airportFee');
  } else if (etaFare.airportFee <= 0) {
    removeField.push('airportFee');
  }
  if (etaFare.meetDriverFee <= 0) {
    removeField.push('meetDriverFee');
  }

  if(etaFare.mode === REGULAR_MODE.timeOrMileage) {
    removeField.push('basicFare')
  } else {
    removeField = [
      ...removeField,
      ...TIME_OR_MILEAGE_BASIC_FARE_FIELD
    ]
  }

  let subField = ModalEditFareField.subField[bookType];
  let mainField = ModalEditFareField.mainField[bookType];
  if (removeField.length > 0) {
    subField = subField.filter(function (item) {
      return !removeField.includes(item);
    });
    mainField = mainField.filter(function (item) {
      return !removeField.includes(item);
    });
  }
  return { subField, mainField }
}

export function calculatorDriverEarningByType(editFare = {}) {
  if(_.isEmpty(editFare)) return 0
  let supplierEarningValue = 0
  if(editFare.supplierEarningType === PAYOUT_CUSTOM_TYPE.amount) 
    supplierEarningValue = editFare.editedSupplierEarning;

  if(editFare.supplierEarningType === PAYOUT_CUSTOM_TYPE.percent) 
    supplierEarningValue = (editFare.subTotal * editFare.editedSupplierEarning) / 100

  return supplierEarningValue
}

export function decodePolyline (encoded) {
  if (!encoded) {
      return [];
  }
  var poly = [];
  var index = 0, len = encoded.length;
  var lat = 0, lng = 0;

  while (index < len) {
    var b, shift = 0, result = 0;

    do {
      b = encoded.charCodeAt(index++) - 63;
      result = result | ((b & 0x1f) << shift);
      shift += 5;
    } while (b >= 0x20);

    var dlat = (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
    lat += dlat;

    shift = 0;
    result = 0;

    do {
      b = encoded.charCodeAt(index++) - 63;
      result = result | ((b & 0x1f) << shift);
      shift += 5;
    } while (b >= 0x20);

    var dlng = (result & 1) != 0 ? ~(result >> 1) : (result >> 1);
    lng += dlng;

    var p = {
      lat: lat / 1e5,
      lng: lng / 1e5,
    };
    poly.push(p);
  }
  return poly;
}

export function checkEnableEditDriverEarning( auth, bookingPermission = [] )  {
  let enableEditDriverEarning = _.get(
    auth,
    'selectedFleet.generalSetting.editDriverEarning',
    false
  );
  const userTypeCurrent = _.get(auth, 'user.userType', '');
  // Nếu là Fleet User thì check thêm permission
  if (userTypeCurrent === userType.FleetUser && enableEditDriverEarning) {
      enableEditDriverEarning = _.get(
          bookingPermission.find((ob) => ob.name === 'EditDriverEarning', {}),
          'isActive',
          false
      );
  }
  return enableEditDriverEarning
}

export function groupDaysToYear (dateData = []) {
  let group = []
  _.forEach(dateData, (date) => {
    let checkDateYear = group.find((ob) => ob.year === moment(date.value).year())
    if (checkDateYear) {
      checkDateYear.days && checkDateYear.days.push(date)
      group = [...group].map(obj => [checkDateYear].find(o => o.year === obj.year) || obj);
    } else {
      let yearObj = {
        year: moment(date.value).year(),
        days: [date]
      }
      group.push(yearObj)
    }
  })
  return groupDaysToMonth(_.sortBy(group, ['year']))
}

export function groupDaysToMonth (group) {
  if (group.length > 0) {
    return group.map((yearObj) => {
      // group by month
      const monthGroup = _.groupBy(yearObj.days, (date) => {
        return moment(date.value).month()
      })
      let monthFinal = []
      for (const key in monthGroup) {
        if (monthGroup[key]) {
          const date = new Date()
          monthFinal.push({
            index: key,
            days: monthGroup[key] && monthGroup[key].sort(function (date1, date2) {
              return new Date(date1.value).getTime() - new Date(date2.value).getTime()
            })
          })
        }
      }
      return {
        year: yearObj.year,
        month: monthFinal
      }
    })
  }
  return []
}

export const removeKeySupplierPermission = (obj) => {
  if (!obj || _.isEmpty(obj)) return {};
  const removeKeys = [
    'externalInfo',
    'networkType',
    'groupId',
    'expectedTime',
    'drvInfo.companyName',
    'spotTime',
    'eta',
    'request.estimate',
    'psgInfo',
    'spareTime',
    'rideType',
    'passenger',
    'estimatedFare',
    'corporateInfo',
    'payment',
    'paidStatus',
    'request.operatorNote',
    'operator.name',
    'recurring.batchId',
  ];
  removeKeys.forEach(path => _.unset(obj, path));
  return obj
}

export const checkOldNewRecurringDays = (oldDays = [], newDays = [], isShowNumber = false) => {
  _.pullAllWith([...oldDays], newDays, (arrVal, othVal) => {
    return arrVal === othVal
  })
  const removedDays = _.map(_.pullAllWith([...oldDays], newDays, (arrVal, othVal) => arrVal === othVal),
    (days) => ({
      value: days,
      type: 'removed'
    })
  )
  const addedDays = _.map(_.pullAllWith([...newDays], oldDays, (arrVal, othVal) => arrVal === othVal),
    (days) => ({
      value: days,
      type: 'new'
    })
  )
  const exitsDays = []
  newDays.map((day) => {
    if (_.some(oldDays, (ob) => moment(ob).isSame(day))) {
      exitsDays.push({
        value: day,
        type: 'old'
      })
    }
  })
  if (isShowNumber) {
    return {
      cancel: removedDays.length || 0,
      add: addedDays.length || 0
    }
  }
  return [
    ...removedDays,
    ...exitsDays,
    ...addedDays,
  ]
}

export function convertDataSourceToDataSend (dataSource) {
  if (dataSource.length > 0) {
    return dataSource.map((dateObj) => dateObj.startDate)
  }
  return []
}

export function enumerateDaysBetweenDates (startDate, endDate) {
  let date = []
  while(moment(startDate) <= moment(endDate)){
    date.push(startDate)
    startDate = moment(startDate).add(1, 'days')._d;
  }
  return date;
}

export const getOutStandingCustomer = async (userId, selectedFleet, getPendingPaymentCustomer) => {
  if (
    userId
  ) {
    try {
      const result = await getPendingPaymentCustomer({
        fleetId: selectedFleet.fleetId,
        userId: userId,
      })
      if(result?.res?.outStanding) return result?.res?.outStanding
      return []
    } catch (error) {
      return []
    }
  } else {
    return []
  }
}

export const checkShowWarningOutstandingAmount = (data, selectedFleet) => {
  if(data?.outStanding?.length > 0 && (
    data.travelerType === 0 || // bookingDetail
    data.traveler_type === 0 || // newBooking
    data.traveler_type === "0" || // newBooking
    selectedFleet?.outstandingPayment?.applyForBusinessProfile
  )) return true
  return false
}

export const statusCanOfferDriver = (status) => {
  return [
    STATUS_BOOKING.action,
    STATUS_BOOKING.pending,
    STATUS_BOOKING.pre,
    STATUS_BOOKING.confirmed,
    STATUS_BOOKING.confirmedDelivery,
  ].includes(status);
};

export const statusOfferDriverOnBookDetail = (status) => {
  if (!status) return true; // Nếu không có status coi như là lúc new booking
  return (
    statusCanOfferDriver(status) ||
    CCLiteCommonFunc.isBookingStatusCompleted(status)
  );
};

export const convertSendEmailEditBook = (data = {}) => {
  const { emailTemplate = {}, emailTemplateInfo = [] } = data || {};
  let result = {
    enableTransport: false,
    enableDelivery: false,
    enableIntercity: false,
  };

  const emailTemplateCustom = emailTemplateInfo.find(e => e.fleetId === data.fleetId) || {};

  const getEnableStatus = (languageField, platform, modifyField) => {
    if (emailTemplate?.[languageField]?.includes('Customized')) {
      return _.get(emailTemplateCustom, modifyField, false);
    }
    return _.get(
      emailTemplateInfo.find(e => e.language === emailTemplate[languageField] && e.platform === platform),
      modifyField,
      false
    );
  };

  result.enableTransport = getEnableStatus('languages', 'CC', 'modifyReservation.enable');
  result.enableDelivery = getEnableStatus('languagesDelivery', 'Delivery', 'modifyDeliveryBooking.enable');
  result.enableIntercity = getEnableStatus('languagesIntercity', 'Intercity', 'modifyIntercityBooking.enable');
  return result;
};

export const checkGateWayAddCardFromURL = (gateWay) => {
  return [NAME_GATEWAY.PayWay, NAME_GATEWAY.ECPay, NAME_GATEWAY.OnePay, NAME_GATEWAY.Xendit, NAME_GATEWAY.PayTabs].includes(gateWay)
}

const getInfoSystem = () => {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const browserInfo = browser.getBrowser();
  const browserName = browserInfo.name;
  const browserVersion = browserInfo.version;

  const osInfo = browser.getOS();
  const osName = osInfo.name;
  const osVersion = osInfo.version;
  const platformType = browser.getPlatformType();

  return `Browser Name: ${browserName} \n Browser Version: ${browserVersion} \n Operating System Name: ${osName} \n Operating System Version: ${osVersion} \n Platform Type: ${platformType}`
}

export const postDataCrash = ({
  errorMessage,
  errorStack,
  isOnerror
}) => {

  // skip error zalo
  if(errorMessage?.includes('zaloJSV2')) return;

  let fleetObject;
    try {
      fleetObject = JSON.parse(localStorage.getItem('fleet')) || {};
    } catch (err) {
    }
  const scripts = document.getElementsByTagName('script');
    let jsFileName = '';
    for (let script of scripts) {
      if (script.src && script.src.includes('.js')) {
        jsFileName = script.src;
        break;
      }
    }
  const sourceMapPath = jsFileName.replace(/\.js$/, '.js.map');
  const user = loadUserProfile()
  if(user?.token) {
    axios
      .post(
        notifyCrashClientAPI,
        {
          fleetId: fleetObject.fleetId,
          errorMessage,
          errorStack,
          sourceMapPath,
          version: packageJson.version,
          systemInfo: getInfoSystem(),
          nameFunc: isOnerror ? 'window.onerror' : 'component'
        },
        { headers: { 'Content-Type': 'application/json', 'Authorization': user.token }}
      )
      .then((res) => {
      })
      .catch((error) => {
        console.log('error Catch notifyCrashToServer', error)
      });
  }
}

let lastError = {};
export const notifyCrashToServer = () => {
  window.onerror = function (message, source, lineno, colno, error) {
    const currentError = { message, source, lineno, colno };
    if (JSON.stringify(currentError) === JSON.stringify(lastError)) {
      // Bỏ qua lỗi trùng lặp
      return;
    }

    lastError = currentError;
    postDataCrash({
      errorMessage: error.message,
      errorStack: error.stack,
      isOnerror: true
    })
  };
}